import { request } from 'core/request';

const getAnnouncements = async () => {
  const data = await request({ method: 'get', url: '/dashboard/announcements' }, false, false);
  return data;
};

export default {
  getAnnouncements
};

import React, { useState, useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'element-react';
import { AlertSetUpMFA, SideBar, Footer, TopNav, ModalBox, Timeout } from 'components';
import ResourceModal from 'pages/Section/ResourceModal';
import { user as userService } from 'services';
import { section as sectionService } from 'services';
import { resource as resourceService, auth as authService } from 'services';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { logger } from 'core/logger';
import { useHistory } from 'react-router';
import { cookies, resetLocalStorage } from 'core/utils';
import { COOKIE_KEY_USER_TOKEN, MODE_LOGIN, DOMAIN_TRANSCOSMOS } from 'core/constants';
import { errorUtils } from 'core/utils';
import fb from 'core/firebase';

function Layout(props) {
  const { t } = useTranslation();
  const {
    updateNotification,
    updateUser,
    notification,
    updateFullLoading,
    currentUser,
    fullloading,
    location,
    addSection,
    sections,
    resource,
    addResources,
    updateResourceModal,
    modeLogin,
    setModeLogin,
    setLoginIdStore
  } = props;
  const [sidebarElement, setSidebarElement] = useState(null);
  const page = useRef(null);
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    logger.info('location: ', window.location);
    async function fetchData() {
      try {
        // You can await here
        updateFullLoading(true);
        const userToken = cookies.getKey(COOKIE_KEY_USER_TOKEN);
        if (!userToken) {
          return history.push('/tci');
        }
        let userInfo = currentUser;
        if (!userInfo) {
          userInfo = await userService.getUser();
          updateUser(userInfo);
        }
        if (userInfo) {
          const { requireChangePassword, loginDomainProvider = '', loginId } = userInfo;
          setLoginIdStore(loginId);
          const loginDomain = loginId.split('@')[1].toLowerCase();
          const modeLoginLocal = localStorage.getItem('modeLogin');
          if (
            MODE_LOGIN.LOGIN_BY_LOGIN_ID === localStorage.getItem('modeLogin') ||
            (!modeLoginLocal && loginDomain === DOMAIN_TRANSCOSMOS)
          )
            setModeLogin(MODE_LOGIN.LOGIN_BY_LOGIN_ID);
          if (requireChangePassword && loginDomainProvider === 'email') {
            return history.push('/change-password');
          }
        }
        addResources({ initialized: false, data: [] });
        const [sections, resources] = await Promise.all([sectionService.getSections(), resourceService.getResources()]);
        addResources({ initialized: true, data: resources });
        addSection(sections);
      } catch (err) {
        logger.error('fetchData -> err', err);
        const { response } = err;
        const { data = {} } = response || {};
        let code = data.code;
        if (err.error) {
          code = err.error;
        }
        if (code === 'user-not-found') {
          toast.warning(t('common.message.user.notFound'));
          setTimeout(doSignOut(), 5000);
        } else {
          errorUtils.handleError(err, toast, updateNotification, updateUser, history, t);
        }
      } finally {
        updateFullLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sideBarCallback = curr => {
    setSidebarElement(curr);
  };

  const renderPageTitle = locationPath => {
    let title = '';
    switch (locationPath) {
      case '/access-history':
        title = t('common.label.accessHistory');
        break;
      default:
        title = '';
    }
    return title;
  };

  const signOut = (displayText, noCancel) => {
    updateNotification({
      showModal: true,
      heading: noCancel ? t('common.message.warning.header') : t('common.message.confirm.header'),
      noCancel: noCancel ? noCancel : false,
      displayText: displayText,
      type: noCancel ? 'warning' : 'confirm',
      buttonOkText: t('common.button.yes'),
      buttonCloseText: t('common.button.no'),
      callback: async closeModal => {
        closeModal();
        updateUser(null);
        try {
          await authService.logout();
          window.wcChannelId = '';
          fb.signOut();
        } catch (err) {
          // do nothing
          console.log('authService.logout ~ error', err);
        } finally {
          doSignOut();
        }
      }
    });
  };

  const doSignOut = () => {
    addResources({ initialized: false, data: [] });
    resetLocalStorage();
    cookies.removeAllTokens();
    setLoginIdStore(null);
    history.push('/tci');
  };

  return (
    <Fragment>
      <div className="">
        {currentUser && (
          <SideBar
            user={currentUser}
            callback={sideBarCallback}
            sections={sections}
            updateResourceModal={updateResourceModal}
          />
        )}
        <div className="page-container" ref={page}>
          {currentUser && (
            <TopNav
              display={false}
              page={page}
              nodes={sidebarElement}
              signOut={signOut}
              user={currentUser}
              history={history}
              location={location}
            />
          )}
          <div className="page-content-wrapper">
            <div className="content sm-gutter">
              {/*<!-- START CONTAINER FLUID -->*/}
              <Timeout signOut={signOut} />
              {currentUser && (
                <div className="container-fluid container-fixed-lg container-fixed-xs">
                  {/*<!-- START CHILDREN -->*/}
                  {renderPageTitle(location.pathname) && <h3>{renderPageTitle(location.pathname)}</h3>}
                  <div className="row">
                    <div className="col-12">
                      <AlertSetUpMFA currentUser={currentUser} history={history} />
                      {props.children}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {currentUser && <Footer />}
          </div>
          {notification.showModal && (
            <ModalBox
              modalClassName={
                notification.modalClassName === 'mfa-modal-notification'
                  ? 'mfa-modal-notification'
                  : 'confirm-yes-or-no'
              }
            />
          )}
          {resource.showModal && <ResourceModal />}
        </div>
      </div>
      {fullloading && <Loading fullscreen={true} />}
    </Fragment>
  );
}

Layout.propTypes = {
  notification: PropTypes.object,
  updateNotification: PropTypes.func,
  currentUser: PropTypes.object,
  updateUser: PropTypes.func,
  updateFullLoading: PropTypes.func,
  fullloading: PropTypes.bool,
  location: PropTypes.object,
  addSection: PropTypes.func,
  sections: PropTypes.any,
  resource: PropTypes.any,
  addResources: PropTypes.func,
  updateResourceModal: PropTypes.func
};

export default Layout;

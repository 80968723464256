import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import { makeid } from 'core/utils';
import { encodeBase64 } from 'core/helpers';
import { logger } from 'core/logger';

export class Firebase {
  constructor(conf) {
    if (!firebase.apps.length) {
      this.default = firebase.initializeApp(conf);
    }
    this.auth = this.default.auth();
    this.storage = this.default.storage();
    this.root = this.storage.ref();
  }

  signIn(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  signOut() {
    return this.auth.signOut();
  }

  getCurrentUser() {
    return new Promise(r => this.auth.onAuthStateChanged(r));
  }

  getUserToken() {
    return this.auth.currentUser.getIdToken(false);
  }

  signInWithGoogle(redirectUrl) {
    let state = makeid(64);
    if (redirectUrl) state = encodeBase64('redirect=' + redirectUrl);

    console.log('signInWithGoogle.info: ', redirectUrl, process.env.REACT_APP_GOOGLE_CLIENT_ID);

    const url = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${
      process.env.REACT_APP_GOOGLE_CLIENT_ID
    }&scope=openid%20email&redirect_uri=${window.location.origin + '/auth/callback'}&state=${state}`;

    return (window.location.href = url);
  }

  getRedirectResult() {
    return this.auth.getRedirectResult();
  }

  getSignedUser() {
    return this.auth.onAuthStateChanged(user => {
      logger.info('callback -> user -> ', user);
      if (user) {
        user.multiFactor.enrolledFactors.forEach(factor => {
          logger.info(`Multi-factor enrolled with ${factor.factorId}`);
        });
      }
      return true;
    });
  }

  signInWithCustomToken(token) {
    return this.auth.signInWithCustomToken(token);
  }

  signInWithCredential = async credential => {
    return this.auth.signInWithCredential(credential);
  };

  downloadMediaUrl(path) {
    const ref = this.storage.ref();
    return ref.child(path).getDownloadURL();
  }

  uploadFile(file, path) {
    const ref = this.storage.ref();
    const metadata = { contentType: file.type };
    return ref.child(path).put(file, metadata);
  }

  deleteFile(path) {
    const ref = this.storage.ref();
    return ref.child(path).delete();
  }

  uploadStringFile(message, path) {
    const ref = this.storage.ref();
    return ref.child(path).putString(message, 'data_url');
  }

  /**
   * Upload file to firebase storage
   * @param {File} file A `Blob` or `File` to upload
   * @param {String} name [Optional] file name
   * @returns {String} url
   */
  upload(file, name) {
    const fileName = name;
    const fileRef = this.root.child(fileName);
    return fileRef.put(file).then(snapshot => {
      return snapshot.ref.getDownloadURL().then(downloadURL => {
        // eslint-disable-next-line
        console.log('File available at', downloadURL);
        return downloadURL;
      });
    });
  }

  /**
   * Upload file to firebase storage
   * @param {String} id root file
   * @param {File} file A `Blob` or `File` to upload
   * @param {String} name file name
   * @returns {any} process
   */
  getUploadProcess(id, file, name) {
    const root = this.storage.ref(id);
    const fileRef = root.child(name);
    const uploadTask = fileRef.put(file);
    return uploadTask;
  }

  put(file, name) {
    const fileName = name;
    const fileRef = this.root.child(fileName);
    return fileRef.put(file);
  }

  async listAllFiles(path) {
    const storageRef = this.storage.ref();
    let files = [];
    let listRef = storageRef.child(path);
    let res = await listRef.listAll().catch(err => console.error(err));
    res.prefixes.forEach(folderRef => {});
    res.items.forEach(itemRef => {
      files.push(itemRef);
    });
    return files;
  }

  /**
   * userId is the id of the uploading user,
   * file must be a File type this will be uploaded to firebase storage server
   */
  uploadProfileImage = async (userId, file) => {
    if (!userId || !file || typeof userId !== 'string' || !file.name) return;
    const timestamp = Math.random()
      .toString(36)
      .substr(2, 9);
    const fileName = `${timestamp}.${file.name.split('.').pop()}`;
    return await this.storage
      .ref('/images')
      .child(userId)
      .child(fileName)
      .put(file)
      .then(async () => {
        const imageRef = this.storage
          .ref('images')
          .child(userId)
          .child(fileName);
        const profileUrl = await imageRef.getDownloadURL().then(fireBaseUrl => {
          return fireBaseUrl;
        });
        return profileUrl;
      });
  };
}

const config_iam = JSON.parse(process.env.REACT_APP_FIREBASE_CONF);
console.log('TCL: config_iam', config_iam);
export default new Firebase(config_iam);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import { withTranslation } from 'react-i18next';
import { STORAGE_KEY_LAST_ACTIVE_TIME } from 'core/constants';

const SESSION_TIME_OUT = 1000 * 60 * 240; // 240 minutes

class Timeout extends Component {
  static propTypes = {
    model: PropTypes.any,
    signOut: PropTypes.func,
    t: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = {
      sessionTimeOut: SESSION_TIME_OUT,
      tokenExpired: false
    };
  }

  componentDidMount() {
    this._checkTimeout();
  }

  _isPrivate = () => {
    return ![
      '/login',
      '/tci',
      '/saml/idp/sso',
      '/change-password',
      '/unlock-request',
      '/mfa',
      '/forgot-password'
    ].includes(window.location.pathname);
  };

  _onAction = e => {
    if (!this.state.tokenExpired && this._isPrivate())
      localStorage.setItem(STORAGE_KEY_LAST_ACTIVE_TIME, new Date().getTime());
  };

  _onActive = e => {
    this._checkTimeout();
  };

  _checkTimeout = () => {
    if (!this._isPrivate()) {
      localStorage.removeItem(STORAGE_KEY_LAST_ACTIVE_TIME);
      return;
    }
    const lat = localStorage.getItem(STORAGE_KEY_LAST_ACTIVE_TIME);
    if (lat) {
      let now = new Date().getTime();
      // The duration time user offline
      const offlineElapsedTime = now - lat;
      // Offline session expired flag
      const tokenExpired = offlineElapsedTime > this.state.sessionTimeOut;
      if (tokenExpired) {
        this.setState({ tokenExpired: true });
        const { t } = this.props;
        this.props.signOut(t('common.message.sessionExpired'), true);
      } else {
        localStorage.setItem(STORAGE_KEY_LAST_ACTIVE_TIME, new Date().getTime());
      }
    } else {
      localStorage.setItem(STORAGE_KEY_LAST_ACTIVE_TIME, new Date().getTime());
    }
  };

  render() {
    return (
      <div>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this._onActive}
          onAction={this._onAction}
          debounce={500}
          timeout={SESSION_TIME_OUT} // Idle after 30 minutes
        ></IdleTimer>
      </div>
    );
  }
}

export default withTranslation()(Timeout);

import React, { useState, useEffect } from 'react';
import 'assets/scss/theme/login.scss';
import BaseInputPassword from 'components/BaseInputPassword';
import BaseLabelCounter from 'components/BaseLabelCounter';
import { Form, FormGroup, FormFeedback } from 'reactstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { logger } from 'core/logger';
import { Loading } from 'element-react';
import { user as userService } from 'services';
import { errorUtils, resetLocalStorage } from 'core/utils';
import ModalBox from 'components/ModalBox';
import moment from 'moment';
import validator from 'core/validator/customValidator';
import { cookies } from 'core/utils';
import { COOKIE_KEY_USER_TOKEN, MODE_LOGIN, maxLengthPassword } from 'core/constants';
import { Copyright, Announcement } from 'components';

function ChangePassword(props) {
  const { t } = useTranslation();
  const {
    updateNotification,
    notification,
    history,
    updateFullLoading,
    fullloading,
    currentUser,
    updateUser,
    modeLogin
  } = props;
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errOldPassword, setErrOldPassword] = useState('');
  const [errNewPassword, setErrNewPassword] = useState('');
  const [errConfirmPassword, setErrConfirmPassword] = useState('');
  const [env, setEnv] = useState('');

  const action = localStorage.getItem('action');

  useEffect(() => {
    async function fetchData() {
      try {
        // You can await here
        updateFullLoading(true);
        const userToken = cookies.getKey(COOKIE_KEY_USER_TOKEN);
        if (!userToken) {
          return history.push('/tci');
        }
        let userInfo = currentUser;
        if (!userInfo) {
          userInfo = await userService.getUser();
          updateUser(userInfo);
        }

        if (userInfo) {
          const {
            setPasswordExpired,
            passwordExpiredDays = 0,
            requireChangePassword = false,
            lastChangedPasswordDate,
            loginDomainProvider
          } = userInfo;
          if (!requireChangePassword || loginDomainProvider !== 'email') {
            return history.push('/');
          }

          if (
            requireChangePassword &&
            setPasswordExpired &&
            moment(lastChangedPasswordDate)
              .add(passwordExpiredDays, 'days')
              .isBefore()
          ) {
            toast.warning(t('changePassword.message.expired'));
          } else {
            if ('LOGIN' === action) toast.warning(t('changePassword.message.required'));
          }
        } else {
          redirectToSignIn();
        }
      } catch (err) {
        logger.error('ChangePassword fetchData -> err', err);
        errorUtils.handleError(err, toast, updateNotification, updateUser, history, t);
      } finally {
        updateFullLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateFullLoading]);

  useEffect(() => {
    const envName = process.env.REACT_APP_ENV_NAME || '';
    setEnv(envName);
  }, []);

  const onOldPasswordChange = value => {
    let err = '';
    if (!value) {
      err = 'common.validator.isRequired';
    } else if (value && value.length > 256) {
      err = 'changePassword.message.password.wrongFormat';
    }
    setOldPassword(value);
    setErrOldPassword(err);
    return !err;
  };

  const onConfirmPasswordChange = value => {
    let err = '';
    if (!value) {
      err = 'common.validator.isRequired';
    } else if (value && value.length > 256) {
      err = 'changePassword.message.password.wrongFormat';
    } else if (value !== newPassword) {
      err = 'changePassword.message.password.notMatch';
    }
    setConfirmPassword(value);
    setErrConfirmPassword(err);
    return !err;
  };

  const onNewPasswordChange = (value, errorMessage) => {
    let err = '';
    if (!value) {
      err = 'common.validator.isRequired';
    } else if (value && value.length > 256) {
      err = 'changePassword.message.password.wrongFormat';
    } else if (errorMessage) {
      err = errorMessage;
    }
    setNewPassword(value);
    setErrNewPassword(err);
    if (errConfirmPassword !== '' && confirmPassword === value) {
      setErrConfirmPassword('');
    } else if (confirmPassword !== value) {
      setErrConfirmPassword('changePassword.message.password.notMatch');
    }
    return !err && !errorMessage;
  };

  const validateAll = () => {
    const validOldPassword = 'LOGIN' === action ? onOldPasswordChange(oldPassword) : true;
    const validNewPassword = onNewPasswordChange(newPassword, errNewPassword);
    const validConfirmPassword = onConfirmPasswordChange(confirmPassword);
    return validOldPassword && validNewPassword && validConfirmPassword;
  };

  const backToSignIn = e => {
    e.preventDefault();
    resetLocalStorage();
    cookies.removeAllTokens();
    localStorage.removeItem('requireChangePassword');
    updateUser(null);
    redirectToSignIn();
  };

  const redirectToSignIn = () => {
    const searchParams = new URLSearchParams(window.location.search);
    let redirectUrl = searchParams.get('redirect');
    // redirectUrl = decodeURIComponent(redirectUrl);
    if (redirectUrl && validator.isURL(redirectUrl)) {
      window.location.replace(redirectUrl);
    } else {
      history.push('/tci');
    }
  };

  const handleChangePassword = async e => {
    e.preventDefault();
    try {
      updateFullLoading(true);
      const validData = validateAll();
      if (!validData) {
        toast.warning(t('common.message.invalidInputs'));
        return;
      }
      await userService.changePassword(window.btoa(oldPassword), window.btoa(newPassword), action);
      toast.success(t('changePassword.message.changed.ok'));
      setTimeout(() => {
        resetLocalStorage();
        cookies.removeAllTokens();
        updateUser(null);
        redirectToSignIn();
      }, 2000);
    } catch (err) {
      logger.error('handleChangePassword -> err', err);
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      switch (code) {
        case 'password-wrong-format':
          toast.warning(t('changePassword.message.password.wrongFormat'));
          break;
        case 'old-password-required':
          toast.warning(t('common.validator.isRequired', { label: t('changePassword.newPassword.label') }));
          break;
        case 'new-password-required':
          toast.warning(t('common.validator.isRequired', { label: t('changePassword.newPassword.label') }));
          break;
        case 'newPassword-must-different-oldPassword':
          toast.warning(t('changePassword.message.newPass.mustBeDifferent.oldPass'));
          break;
        case 'wrong-password':
          toast.warning(t('changePassword.message.old.password.incorrect'));
          break;
        case 'password-exist':
          toast.warning(t('changePassword.message.cannot.use.recent.password'));
          break;
        case 'user-not-found':
          toast.warning(t('common.message.user.notFound'));
          break;
        default:
          errorUtils.handleError(err, toast, updateNotification, updateUser, history, t);
      }
    } finally {
      updateFullLoading(false);
    }
  };

  return (
    <div className="login-wrapper login-page">
      <div className="row no-gutters">
        <div className="d-none d-sm-none d-md-block col-md-5 col-lg-6 col-xl-7">
          <div className="background" style={{ backgroundImage: 'url(' + require('assets/img/login/bg.jpg') + ')' }}>
            <div className="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 p-b-10">
              <h2 className="semi-bold text-white">{t('login.slogan')}</h2>
              <Copyright className="small" />
            </div>
            <div className="backdrop" />
          </div>
          {/* <Announcement /> */}
        </div>
        <div className="col-sm-12 col-md-7 col-lg-6 col-xl-5">
          <div className="login-container bg-white">
            <div className="m-l-0 m-r-0 sm-p-l-15 sm-p-r-15 sm-p-t-40 m-p-l-0 m-p-r-0 content">
              {/* <Announcement className="small-screen-announcement" /> */}
              <div className="row">
                <div className="col-sx-12 col-6 text-sx-center d-flex align-items-center">
                  <img
                    style={{ maxHeight: '60px', marginTop: '7px' }}
                    src={require('assets/img/logo_2x.png')}
                    alt="SSO Portal"
                  />
                  <span className="environment-name-login">{env}</span>
                </div>
                <div className="col-sx-12 col-6 text-right text-sx-center">
                  <img
                    style={{ maxHeight: '70px' }}
                    className="img-fluid"
                    src={require('assets/img/TCI-PF-certificate.png')}
                    alt="TCI-PF System Cert t-passport"
                  />
                </div>
              </div>
              <p className="p-t-35">{t('changePassword.title')}</p>
              <Form method="post" className="p-t-15" action="#" autoComplete="off" onSubmit={handleChangePassword}>
                {'LOGIN' === action && (
                  <FormGroup className="form-group-default">
                    <BaseLabelCounter label={t('changePassword.oldPassword.label')} />
                    <BaseInputPassword
                      name="oldPassword"
                      showStrength={false}
                      showEyeIcon={false}
                      placeholder={t('placeholder.input.Enter', {
                        field: t('changePassword.oldPassword.label')
                      })}
                      maxLength={maxLengthPassword}
                      value={oldPassword}
                      onChange={onOldPasswordChange}
                    />
                  </FormGroup>
                )}
                <FormFeedback className="d-block">
                  {t(errOldPassword, { label: t('changePassword.oldPassword.label') })}
                </FormFeedback>
                <FormGroup className="form-group-default">
                  <BaseLabelCounter
                    label={t('changePassword.newPassword.label')}
                    value={newPassword}
                    length={maxLengthPassword}
                  />
                  <BaseInputPassword
                    name="newPassword"
                    placeholder={t('placeholder.input.Enter', {
                      field: t('changePassword.newPassword.label')
                    })}
                    maxLength={maxLengthPassword}
                    value={newPassword}
                    onChange={onNewPasswordChange}
                  />
                </FormGroup>
                <FormFeedback className="d-block">
                  {t(errNewPassword, { label: t('changePassword.newPassword.label') })}
                </FormFeedback>
                <FormGroup className="form-group-default">
                  <BaseLabelCounter label={t('changePassword.confirmPassword.label')} />
                  <BaseInputPassword
                    name="confirmPassword"
                    showStrength={false}
                    showEyeIcon={false}
                    placeholder={t('placeholder.input.Enter', {
                      field: t('changePassword.confirmPassword.label')
                    })}
                    maxLength={maxLengthPassword}
                    value={confirmPassword}
                    onChange={onConfirmPasswordChange}
                  />
                </FormGroup>
                <FormFeedback className="d-block">
                  {t(errConfirmPassword, { label: t('changePassword.confirmPassword.label') })}
                </FormFeedback>
                <div className="mt-2 float-left forgot-password">
                  {t('common.label.already.login')}{' '}
                  <a href={`/${MODE_LOGIN.LOGIN_BY_EMAIL === modeLogin ? 'login' : 'tci'}`} onClick={backToSignIn}>
                    {t('login.signin.button')}
                  </a>
                </div>
                <div className="mt-5">
                  <button className="btn btn-primary btn-cons m-t-12 btn-block" type="submit">
                    {t('changePassword.button.changePassword')}
                  </button>
                </div>
              </Form>
              <div className="copyright-xs">
                <Copyright className="small" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {notification.showModal && <ModalBox />}
      {fullloading && <Loading fullscreen={true} />}
    </div>
  );
}
export default ChangePassword;

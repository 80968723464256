/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import 'assets/scss/theme/login.scss';
import BaseInput from 'components/BaseInput';
import BaseLabelCounter from 'components/BaseLabelCounter';
import ModalBox from 'components/ModalBox';
import {
  COOKIE_KEY_USER_TOKEN,
  COOKIE_KEY_REFRESH_TOKEN,
  STORAGE_KEY_APP_SYNC_SIGNED_IN,
  COOKIE_KEY_APP_ATM_TOKEN,
  COOKIE_KEY_APP_ATM_TOKEN_EXP,
  MFA_METHODS,
  MODE_LOGIN
} from 'core/constants';
import { logger } from 'core/logger';
import { cookies, errorUtils, resetLocalStorage } from 'core/utils';
import validator from 'core/validator/customValidator';
import { Loading } from 'element-react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Form, FormFeedback, FormGroup } from 'reactstrap';
import { auth as authService, user as userService } from 'services';
import { Copyright, Announcement, Confirm } from 'components';

function MFA(props) {
  logger.info('MFA');
  const { t } = useTranslation();
  const {
    history,
    updateFullLoading,
    fullloading,
    modeLogin,
    loginIdStore,
    updateUser,
    updateNotification,
    notification
  } = props;
  const [otp, setOTP] = useState('');
  const [errorOTP, setErrorOTP] = useState('');
  const [resourceId, setResourceId] = useState('');
  const [viaEmailOTP, setViaEmailOTP] = useState(false);
  const mountedRef = useRef(true);
  const action = localStorage.getItem('action');
  const mfa = localStorage.getItem('mfa');
  const mfaMethod = localStorage.getItem('mfaMethod');
  const [countDown, setCountDown] = useState(0);
  const [_modeLogin, setModeLogin] = useState(modeLogin);
  const [_loginId, setLoginId] = useState(loginIdStore);
  const [env, setEnv] = useState('');
  let timer = null;

  const checkCountDown = mfaMethod => {
    const selectedMethod = mfaMethod || localStorage.getItem('mfaMethod');
    if (MFA_METHODS.AUTHENTICATOR === selectedMethod || !selectedMethod) return;
    let ttl = 0;
    let seconds = 0;
    if (MFA_METHODS.SMS === selectedMethod) {
      ttl = localStorage.getItem('sendSmsOtpCodeTimestamp');
    } else if (MFA_METHODS.EMAIL === selectedMethod) {
      ttl = localStorage.getItem('sendEmailOtpCodeTimestamp');
    }
    if (ttl) {
      try {
        seconds = parseInt(ttl) - Math.floor(new Date().getTime() / 1000) + 30;
      } catch (error) {
        removeOtpCodeTimestamp(selectedMethod);
      }
    }
    setCountDown(seconds);
    if (seconds <= 0) removeOtpCodeTimestamp(selectedMethod);
  };

  const removeOtpCodeTimestamp = mfaMethod => {
    if (MFA_METHODS.SMS === mfaMethod) {
      localStorage.removeItem('sendSmsOtpCodeTimestamp');
    } else if (MFA_METHODS.EMAIL === mfaMethod) {
      localStorage.removeItem('sendEmailOtpCodeTimestamp');
    }
  };

  useEffect(() => {
    if (countDown > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = setInterval(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [countDown]);

  useEffect(() => {
    let id = loginIdStore;
    logger.info('loginIdStore: ', loginIdStore);
    logger.info('action: ', action);
    if (!loginIdStore) {
      id = localStorage.getItem('loginIdStore');
      const mode = localStorage.getItem('modeLogin');
      if (id && mode) {
        setLoginId(id);
        setModeLogin(mode);
      } else {
        history.push('/');
      }
    }
    const searchParams = new URLSearchParams(window.location.search);
    setResourceId(searchParams.get('resourceId'));
    checkCountDown();

    return () => {
      mountedRef.current = false; // clean up function
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkSessionExpired = () => {
    const mfaLoginTimestamp = localStorage.mfaLoginTimestamp;
    const nowTimestamp = Math.round(Date.now() / 1000);
    if (mfaLoginTimestamp && nowTimestamp - Number(mfaLoginTimestamp) >= 1800) {
      const tokenExpiredError = { error: { code: 'token-expired' }, response: { data: { code: 'token-expired' } } };
      errorUtils.handleError(tokenExpiredError, toast, updateNotification, updateUser, history, t);
      return true;
    }
    return false;
  };

  useEffect(() => {
    checkSessionExpired();
  }, []);

  useEffect(() => {
    const envName = process.env.REACT_APP_ENV_NAME || '';
    setEnv(envName);
  }, []);

  const onOTPChange = value => {
    logger.info('onOTPChange -> value ==> ', value);
    let err = '';
    if (!value) {
      err = 'common.validator.isRequired';
    }

    setOTP(value);
    setErrorOTP(err);
    return !err;
  };

  const receiveOtpByEmail = async () => {
    try {
      updateFullLoading(true);
      // Check account is able to receive OTP by email or not
      await userService.receiveOtpByEmail(_loginId, 'LOGIN');
      setViaEmailOTP(true);
      const ttl = Math.floor(new Date().getTime() / 1000);
      localStorage.setItem('sendEmailOtpCodeTimestamp', ttl);
      checkCountDown(MFA_METHODS.EMAIL);
      toast.success(t('mfa.message.sentMail.ok'));
    } catch (err) {
      logger.error('receiveOtpByEmail -> err', err);
      setViaEmailOTP(false);
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      if (code === 'user-not-found') {
        toast.warning(t('common.message.user.notFound'));
      } else if (code === 'account-is-locked') {
        toast.warning(t('common.message.user.locked'));
      } else if (code === 'account-login-not-provided-email') {
        toast.warning(t('forgotPassword.message.account.wrong.provider'));
      } else if (code === 'have-no-email-to-receive-otp') {
        toast.warning(t('mfa.msg.have.no.email.to.receive.otp'));
      } else if (code === 'server-is-maintenance') {
        window.location.reload();
      } else {
        toast.error(t('common.message.error'));
      }
    } finally {
      updateFullLoading(false);
    }
  };

  const handleVerifyOTP = async e => {
    logger.info('verifyOTP -> info: ', e);
    e.preventDefault();
    const _checkSessionExpired = checkSessionExpired();
    if (_checkSessionExpired) return;
    if (!action) {
      toast.warning(t('common.message.sessionExpired'));
      return;
    }
    try {
      updateFullLoading(true);
      // onOTPChange(otp);
      if (!onOTPChange(otp)) {
        toast.warning(t('common.message.invalidInputs'));
        return;
      }
      // Verify for Login
      if ('LOGIN' === action) {
        const data = await authService.verifyOTPLogin(_loginId, otp);
        const { userToken, refreshToken = '', atmToken, atmTokenExpiresAt } = data;
        localStorage.setItem(STORAGE_KEY_APP_SYNC_SIGNED_IN, true);
        cookies.setKey(COOKIE_KEY_USER_TOKEN, userToken);
        cookies.setKey(COOKIE_KEY_REFRESH_TOKEN, refreshToken);
        cookies.setKey(COOKIE_KEY_APP_ATM_TOKEN, atmToken);
        cookies.setKey(COOKIE_KEY_APP_ATM_TOKEN_EXP, atmTokenExpiresAt);
        redirectTo(false);
      } else if ('FORGOT_PASSWORD' === action || 'REQUEST_UNLOCK' === action) {
        const data = await authService.verifyOTP(_loginId, mfaMethod, otp, true);
        const { userToken } = data;
        localStorage.setItem(STORAGE_KEY_APP_SYNC_SIGNED_IN, true);
        cookies.setKey(COOKIE_KEY_USER_TOKEN, userToken);
        const searchParams = new URLSearchParams(window.location.search);
        let redirectUrl = searchParams.get('redirect');
        // redirectUrl = decodeURIComponent(redirectUrl);
        if (redirectUrl && validator.isURL(redirectUrl)) {
          history.push(`/change-password?redirect=${redirectUrl}`);
        } else {
          history.push('/change-password');
        }
      }
    } catch (err) {
      logger.error('handleUnlockAccount -> err', err);
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      switch (code) {
        case 'user-not-found':
          toast.warning(t('common.message.user.notFound'));
          break;
        case 'resource-not-found':
          toast.warning(t('common.message.resource.notFound'));
          break;
        case 'generate-otp-error':
          toast.warning(t('mfa.message.generate.otp.error'));
          break;
        case 'invalid-otp':
          toast.warning(t('mfa.message.invalid.otp'));
          let mfaRetry = localStorage.getItem('mfaRetry') || 0;
          try {
            const count = parseInt(mfaRetry);
            if (count >= 5) {
              backToSignIn(e);
            } else {
              localStorage.setItem('mfaRetry', count + 1);
            }
          } catch (error) {
            logger.error(error);
            backToSignIn(e);
          }
          break;
        case 'otp-expired':
          toast.warning(t('mfa.message.otp.expired'));
          break;
        case 'account-is-locked':
          toast.warning(t('common.message.user.locked'));
          history.push('/tci');
          break;
        case 'invalid-token':
          errorUtils.handleError(err, toast, updateNotification, updateUser, history, t);
          break;
        case 'max-25-connection':
          toast.warning(t('Common.Error.TooManySessions'));
          break;
        case 'server-is-maintenance':
          window.location.reload();
        default:
          toast.error(t('common.message.error'));
      }
    } finally {
      updateFullLoading(false);
    }
  };

  const backToSignIn = e => {
    e.preventDefault();
    resetLocalStorage();
    cookies.removeAllTokens();
    redirectTo(true);
  };

  const redirectTo = (toSignIn = true) => {
    const searchParams = new URLSearchParams(window.location.search);
    let redirectUrl = searchParams.get('redirect');
    // redirectUrl = decodeURIComponent(redirectUrl);
    if (redirectUrl && validator.isURL(redirectUrl)) {
      if (redirectUrl.includes('/saml/idp/sso?') && !redirectUrl.includes('&redirectSSO'))
        redirectUrl = `${redirectUrl}&redirectSSO=${true}`;
      window.location.replace(redirectUrl);
    } else {
      if (toSignIn) {
        history.push('/tci');
      } else {
        history.push('/');
      }
    }
  };

  const handleResendOTP = async e => {
    e.preventDefault();
    const _checkSessionExpired = checkSessionExpired();
    if (_checkSessionExpired) return;
    try {
      let mfaRetry = localStorage.getItem('mfaRetry') || 0;
      try {
        mfaRetry = parseInt(mfaRetry);
        if (mfaRetry > 4) {
          backToSignIn(e);
          return;
        }
        localStorage.setItem('mfaRetry', mfaRetry + 1);
      } catch (error) {
        logger.error(error);
        localStorage.setItem('mfaRetry', 1);
      }
      updateFullLoading(true);
      const reSendingOTPMethod = localStorage.getItem('mfaMethod');
      await authService.resendOTP(_loginId, resourceId, viaEmailOTP ? 'EMAIL' : reSendingOTPMethod).catch(error => {
        const response = error.response || {};
        if (response.status === 429) {
          const data = response.data || {};
          if (data.code === 'too-many-sms') {
            localStorage.setItem('sendSmsOtpCodeTimestamp', data.ttl);
            checkCountDown(MFA_METHODS.SMS);
          }
        }
        throw error;
      });
      const ttl = Math.floor(new Date().getTime() / 1000);
      if (MFA_METHODS.SMS === reSendingOTPMethod && !viaEmailOTP) {
        localStorage.setItem('sendSmsOtpCodeTimestamp', ttl);
        checkCountDown(MFA_METHODS.SMS);
        toast.success(t('mfa.message.sentSMS.ok'));
      } else {
        localStorage.setItem('sendEmailOtpCodeTimestamp', ttl);
        checkCountDown(MFA_METHODS.EMAIL);
        toast.success(t('mfa.message.sentMail.ok'));
      }
    } catch (err) {
      logger.error('handleResendOTP -> err', err);
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      switch (code) {
        case 'user-not-found':
          toast.warning(t('common.message.user.notFound'));
          break;
        case 'generate-otp-error':
          toast.warning(t('mfa.message.generate.otp.error'));
          break;
        case 'too-many-sms':
          toast.warning(t('mfa.message.too.many.sms'));
          break;
        case 'account-is-locked':
          toast.warning(t('common.message.user.locked'));
          history.push('/tci');
          break;
        case 'max-25-connection':
          toast.warning(t('Common.Error.TooManySessions'));
          break;
        case 'server-is-maintenance':
          window.location.reload();
        default:
          toast.error(t('common.message.error'));
      }
    } finally {
      updateFullLoading(false);
    }
  };

  return (
    <div className="login-wrapper login-page">
      <div className="row no-gutters">
        <div className="d-none d-sm-none d-md-block col-md-5 col-lg-6 col-xl-7">
          <div className="background" style={{ backgroundImage: 'url(' + require('assets/img/login/bg.jpg') + ')' }}>
            <div className="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 p-b-10">
              <h2 className="semi-bold text-white">{t('login.slogan')}</h2>
              <Copyright className="small" />
            </div>
            <div className="backdrop" />
          </div>
          {/* <Announcement /> */}
        </div>
        <div className="col-sm-12 col-md-7 col-lg-6 col-xl-5">
          <div className="login-container bg-white">
            <div className="m-l-0 m-r-0 sm-p-l-15 sm-p-r-15 sm-p-t-40 m-p-l-0 m-p-r-0 content">
              {/* <Announcement className="small-screen-announcement" /> */}
              <div className="row">
                <div className="col-sx-12 col-6 text-sx-center d-flex align-items-center">
                  <img
                    style={{ maxHeight: '60px', marginTop: '7px' }}
                    src={require('assets/img/logo_2x.png')}
                    alt="SSO Portal"
                  />
                  <span className="environment-name-login">{env}</span>
                </div>
                <div className="col-sx-12 col-6 text-right text-sx-center">
                  <img
                    style={{ maxHeight: '70px' }}
                    className="img-fluid"
                    src={require('assets/img/TCI-PF-certificate.png')}
                    alt="TCI-PF System Cert t-passport"
                  />
                </div>
              </div>
              <p className="p-t-35">{t('mfa.title')}</p>
              <Form method="post" className="p-t-15" action="#" autoComplete="off" onSubmit={handleVerifyOTP}>
                <FormGroup className="form-group-default">
                  <BaseLabelCounter label={t('mfa.otp.label')} />
                  <BaseInput
                    name="otp"
                    placeholder={t('placeholder.input.Enter', { field: t('mfa.otp.label') })}
                    value={otp}
                    onChange={onOTPChange}
                    autoFocus={true}
                    maxLength={6}
                    customizeRegex={{ otp: /^[0-9０-９]*$/ }}
                  />
                </FormGroup>
                <FormFeedback className="d-block">
                  {errorOTP ? t(errorOTP, { label: t('mfa.otp.label') }) : ''}
                </FormFeedback>
                {'LOGIN' === action && 'EMAIL' !== mfaMethod && (
                  <div>
                    <div className="m-t-2 float-left forgot-password">
                      <a href="javascript:void(0)" onClick={receiveOtpByEmail}>
                        {t('mfa.link.receive.otp.email')}
                      </a>
                    </div>
                    <br></br>
                  </div>
                )}
                <div className="m-t-2 float-left forgot-password">
                  {t('common.label.already.login')}{' '}
                  <a href={`/${MODE_LOGIN.LOGIN_BY_EMAIL === _modeLogin ? 'login' : 'tci'}`} onClick={backToSignIn}>
                    {t('login.signin.button')}
                  </a>
                </div>
                <div className="m-t-5">
                  <button className="btn btn-primary btn-cons m-t-10 btn-block" type="submit">
                    {t('mfa.button.otp.verify')}
                  </button>
                  {('AUTHENTICATOR' !== mfaMethod || mfa === 'false' || viaEmailOTP) && (
                    <button
                      className="btn btn-info btn-cons m-t-10 btn-block"
                      type="submit"
                      disabled={countDown > 0}
                      onClick={handleResendOTP}
                    >
                      {countDown > 0 ? t('mfa.button.otp.resend') + ' ' + countDown : t('mfa.button.otp.resend')}
                    </button>
                  )}
                </div>
              </Form>
              <div className="copyright-xs">
                <Copyright className="small" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {fullloading && <Loading fullscreen={true} />}
      {notification.showModal && <ModalBox />}
    </div>
  );
}
export default MFA;

import React, { PureComponent } from 'react';
import { Modal, ModalHeader, ModalBody, Card, Button, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import momentTZ from 'moment-timezone';
import { toast } from 'react-toastify';
import i18n from 'i18n';
import './index.scss';
import defaultIcon from 'assets/images/resource/icon.png';

export default class CardTable extends PureComponent {
  static propTypes = {
    tasks: PropTypes.array,
    selectedTasks: PropTypes.array,
    onSelectCard: PropTypes.func,
    onRendered: PropTypes.func,
    ableToSelect: PropTypes.bool
  };

  static defaultProps = {
    tasks: [],
    selectedTasks: [],
    onRendered: () => {},
    onSelectCard: () => {},
    ableToSelect: false
  };

  constructor(props) {
    super(props);
    this.script = null;
    this.state = {
      loadTotal: 0,
      loadNum: 10,
      page: 0,
      tagScriptUrl: 't-tag.js?projectid=TPASSPORT-SSO-PORTAL&sendonload=true&maxlogs=15',
      isShowWarningModal: false,
      selectedCard: {}
    };
  }

  loadTagScript = (removeOldScript = true) => {
    // remove old script
    if (removeOldScript && this.script) {
      console.log('remove old t-tag script');
      document.body.removeChild(this.script);
    }
    // build new script
    console.log('load new t-tag script');
    this.script = document.createElement('script');
    this.script.src = this.state.tagScriptUrl;
    this.script.async = true;
    this.script.setAttribute('nonce', process.env.REACT_APP_RANDOME_NUMBER);
    document.body.appendChild(this.script);
  };

  componentDidMount() {
    this.loadTagScript();
  }

  componentWillUnmount() {
    document.body.removeChild(this.script);
  }

  onClick = (item, isAccess = false) => {
    const { healthCheckStatus } = item;
    this.setState({ selectedCard: item });
    if (!healthCheckStatus && !isAccess) {
      this.setState({ isShowWarningModal: true });
      return;
    }
    this.setState({ isShowWarningModal: false });
    if (item.isMaintenance) {
      toast.warning(i18n.t('label.resource.isMaintenance'));
      return;
    }
    if (this.props.ableToSelect) {
      this.props.onSelectCard(item.id);
    } else {
      this.props.onClickCard(item);
    }
  };

  loadFunc = pageNumber => {
    const count = parseInt(this.state.loadNum * pageNumber);
    this.setState({ loadTotal: count, page: pageNumber });
    this.loadTagScript(true);
  };

  render() {
    const { tasks } = this.props;
    const { selectedCard, isShowWarningModal } = this.state;
    const lang = localStorage.getItem('sysLanguage') || 'en_US';
    const formatDate = lang === 'en_US' ? 'MM/DD/YYYY HH:mm' : 'YYYY/MM/DD HH:mm';
    return (
      // <InfiniteScroll
      //   pageStart={page}
      //   loadMore={this.loadFunc}
      //   hasMore={tasks.length > loadTotal}
      //   loader={
      //     <div key={0} className="loader p-l-0 p-b-20 text-muted">
      //       {i18n.t('common.label.loading')}
      //     </div>
      //   }
      // >
      <div className="row rs-cards">
        {tasks.map((item, index) => {
          // {tasks.slice(0, this.state.loadTotal).map((item, index) => {
          const {
            action,
            name,
            description,
            timezone,
            isMaintenance,
            maintenanceStartDate,
            maintenanceEndDate,
            iconURL = '',
            healthCheckUrl,
            healthCheckStatus
          } = item;

          return (
            <div className="rs-card-item col-xs-12 col-sm-12 col-md-6" key={`resource_card_index_${index}`}>
              <Card className="card-default card-shadow text-center" key={`resource_card_index_${index}`}>
                {isMaintenance && (
                  <div className="card-body">
                    {`${i18n.t('label.maintenance.resource', {
                      startDate: momentTZ.tz(maintenanceStartDate, timezone).format(formatDate),
                      endDate: momentTZ.tz(maintenanceEndDate, timezone).format(formatDate),
                      timezone
                    })}`}
                  </div>
                )}
                {!isMaintenance && (
                  <div className="card-img">
                    <span className="img-helper"></span>
                    <label className="image-item">
                      <img src={iconURL || defaultIcon} alt={description} width="100%" height="100%" />
                    </label>
                  </div>
                )}
                <div className="card-header">
                  <h2 className="title">{name}</h2>
                  {healthCheckUrl && (
                    <div className={`status ${healthCheckStatus ? 'active' : 'detected'}`}>
                      <span className="status-icon"></span>
                      <span className="status-text">
                        {i18n.t('resource.service')}:{' '}
                        {healthCheckStatus ? i18n.t('resource.operational') : i18n.t('resource.issue.detected')}
                      </span>
                    </div>
                  )}
                </div>
                {action && !isMaintenance && action === 2 && (
                  <div className="access" data-tt-click-send={`${name}`}>
                    <div className="access-link" data-tt-click-send={`${name}`}>
                      <Button onClick={() => this.onClick(item)}>{i18n.t('Common.Button.Open')}</Button>
                      {/* <Button data-tt-click-send={`[button]${name}`}>
                        {i18n.t('common.label.access')} <i className="material-icons">launch</i>
                      </Button> */}
                    </div>
                    {/* <div className="mask" data-tt-click-send={`[mask]${name}`}></div> */}
                  </div>
                )}
              </Card>
            </div>
          );
        })}
        <Modal isOpen={isShowWarningModal} centered zIndex={2050} modalClassName="modal-warning-active" size="md">
          <ModalHeader toggle={() => this.setState({ isShowWarningModal: false })}>
            <div className="left-header">
              <span className="name">{selectedCard?.name}</span>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="modal-description">
              <h4>{i18n.t('confirm.service.issue.detected')}</h4>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.setState({ isShowWarningModal: false })}>{i18n.t('common.button.no')}</Button>
            <Button onClick={() => this.onClick(selectedCard, true)} color="primary">
              {i18n.t('common.button.yes')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      // </InfiniteScroll>
    );
  }
}

/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import 'assets/scss/theme/login.scss';
import BaseInput from 'components/BaseInput';
import BaseLabelCounter from 'components/BaseLabelCounter';
import { Form, FormGroup, FormFeedback } from 'reactstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { logger } from 'core/logger';
import { Loading } from 'element-react';
import { user as userService } from 'services';
import { auth as authService } from 'services';
import validator from 'core/validator/customValidator';
import { cookies, resetLocalStorage } from 'core/utils';
import { MODE_LOGIN } from 'core/constants';
import { Copyright, Announcement, Confirm } from 'components';

function ForgotPassword(props) {
  const { t } = useTranslation();
  const { history, updateFullLoading, fullloading, modeLogin, loginIdStore, setLoginIdStore } = props;

  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [countDown, setCountDown] = useState(0);
  const [env, setEnv] = useState('');
  let timer = null;

  const checkCountDown = key => {
    const ttl = localStorage.getItem(key);
    let seconds = 0;
    if (ttl) {
      try {
        seconds = parseInt(ttl) - Math.floor(new Date().getTime() / 1000);
      } catch (error) {
        localStorage.removeItem(key);
      }
    }
    setCountDown(seconds);
    if (seconds <= 0) localStorage.removeItem(key);
  };

  useEffect(() => {
    if (countDown > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = setInterval(() => {
        setCountDown(countDown - 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [countDown]);

  useEffect(() => {
    if (!loginIdStore) {
      history.push('/tci');
    }
    setEmail(loginIdStore);
    checkCountDown(loginIdStore);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const envName = process.env.REACT_APP_ENV_NAME || '';
    setEnv(envName);
  }, []);

  const onEmailChange = value => {
    let err = '';
    if (!value) {
      err = 'common.validator.isRequired';
    } else if (!validator.isCorrectEmail(value) && MODE_LOGIN.LOGIN_BY_EMAIL === modeLogin) {
      err = 'common.validator.invalidEmail';
    }
    setEmail(value);
    checkCountDown(value);
    setErrorEmail(err);
    return !err;
  };

  const backToSignIn = e => {
    e.preventDefault();
    resetLocalStorage();
    cookies.removeAllTokens();
    redirectToSignIn();
  };

  const redirectToSignIn = () => {
    const searchParams = new URLSearchParams(window.location.search);
    let redirectUrl = searchParams.get('redirect');
    // redirectUrl = decodeURIComponent(redirectUrl);
    if (redirectUrl && validator.isURL(redirectUrl)) {
      window.location.replace(redirectUrl);
    } else {
      history.push('/tci');
    }
  };

  const receiveOtpByEmail = async () => {
    try {
      updateFullLoading(true);
      // Validate input email
      if (!onEmailChange(email)) {
        toast.warning(t('common.message.invalidInputs'));
        return;
      }

      // Check account is able to receive OTP by email or not
      const result = await userService.receiveOtpByEmail(email, 'FORGOT_PASSWORD');
      if (result) {
        setLoginIdStore(email);
        localStorage.removeItem('mfaRetry');
        localStorage.setItem('mfaMethod', 'EMAIL');
        localStorage.setItem('action', 'FORGOT_PASSWORD');
        toast.success(t('mfa.message.sentMail.ok'));
        localStorage.setItem('mfaLoginTimestamp', Math.round(Date.now() / 1000));
        history.push('/mfa');
      }
    } catch (err) {
      logger.error('receiveOtpByEmail -> err', err);
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      if (code === 'user-not-found') {
        toast.warning(t('common.message.user.notFound'));
      } else if (code === 'account-is-locked') {
        toast.warning(t('common.message.user.locked'));
      } else if (code === 'account-login-not-provided-email') {
        toast.warning(t('forgotPassword.message.account.wrong.provider'));
      } else if (code === 'have-no-email-to-receive-otp') {
        toast.warning(t('mfa.msg.have.no.email.to.receive.otp'));
      } else if (code === 'server-is-maintenance') {
        window.location.reload();
      } else {
        toast.error(t('common.message.error'));
      }
    } finally {
      updateFullLoading(false);
    }
  };

  const handleForgotPassword = async e => {
    e.preventDefault();
    try {
      updateFullLoading(true);
      // Validate input email
      if (!onEmailChange(email)) {
        toast.warning(t('common.message.invalidInputs'));
        return;
      }

      // Check account is able to reset password or not
      const result = await userService.ableToResetPassword(email).catch(error => {
        const response = error.response || {};
        if (response.status === 429) {
          const data = response.data || {};
          if (data.code === 'too-many-sms') {
            localStorage.setItem(email, data.ttl);
            checkCountDown(email);
          }
        }
        throw error;
      });
      if (result) {
        const { mfa, mfaMethod, requireOTP, isChangeEmail } = result;
        if (isChangeEmail) {
          Confirm({
            message: t('Common.Alert.SMSMFA.ChangeToEmail')
          }).then(async result => {
            if (result) {
              let _res = await authService.sendOTPToEmail(email).catch(err => {
                console.log(err);
                history.push('/tci');
              });
              if (_res) {
                setLoginIdStore(email);
                localStorage.removeItem('mfaRetry');
                localStorage.setItem('mfaMethod', 'EMAIL');
                localStorage.setItem('action', 'FORGOT_PASSWORD');
                toast.success(t('mfa.message.sentMail.ok'));
                localStorage.setItem('mfaLoginTimestamp', Math.round(Date.now() / 1000));
                history.push('/mfa');
              }
            } else {
              history.push('/tci');
            }
          });
        } else if (isChangeEmail === false) {
          toast.warning(<div dangerouslySetInnerHTML={{ __html: t('Common.Alert.SMSMFA.ContactManager') }} />);
        } else if (mfa || requireOTP) {
          setLoginIdStore(email);
          const ttl = Math.floor(new Date().getTime() / 1000) + 30;
          if (mfaMethod === 'SMS') {
            localStorage.setItem(email, ttl);
          }
          localStorage.setItem('mfa', mfa);
          localStorage.setItem('mfaMethod', mfaMethod);
          localStorage.setItem('action', 'FORGOT_PASSWORD');
          localStorage.removeItem('mfaRetry');
          localStorage.setItem('mfaLoginTimestamp', Math.round(Date.now() / 1000));
          history.push('/mfa');
        }
      }
    } catch (err) {
      logger.error('handleForgotPassword -> err', err);
      const { response } = err;
      const { data = {} } = response || {};
      let code = data.code;
      if (err.error) {
        code = err.error;
      }
      if (code === 'user-not-found') {
        toast.warning(t('common.message.user.notFound'));
      } else if (code === 'account-is-locked') {
        toast.warning(t('common.message.user.locked'));
      } else if (code === 'account-login-not-provided-email') {
        toast.warning(t('forgotPassword.message.account.wrong.provider'));
      } else if (code === 'contact-manager-to-reset-password') {
        toast.warning(t('common.message.contact.to.reset.password'));
      } else if (code === 'too-many-sms') {
        toast.warning(t('mfa.message.too.many.sms'));
      } else if (code === 'country-not-support-sms') {
        toast.warning(t('common.message.mobile.not.support.country'));
      } else if (code === 'server-is-maintenance') {
        window.location.reload();
      } else {
        toast.error(t('common.message.error'));
      }
    } finally {
      updateFullLoading(false);
    }
  };

  return (
    <div className="login-wrapper login-page">
      <div className="row no-gutters">
        <div className="d-none d-sm-none d-md-block col-md-5 col-lg-6 col-xl-7">
          <div className="background" style={{ backgroundImage: 'url(' + require('assets/img/login/bg.jpg') + ')' }}>
            <div className="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 p-b-10">
              <h2 className="semi-bold text-white">{t('login.slogan')}</h2>
              <Copyright className="small" />
            </div>
            <div className="backdrop" />
          </div>
          {/* <Announcement /> */}
        </div>
        <div className="col-sm-12 col-md-7 col-lg-6 col-xl-5">
          <div className="login-container bg-white">
            <div className="m-l-0 m-r-0 sm-p-l-15 sm-p-r-15 sm-p-t-40 m-p-l-0 m-p-r-0 content">
              {/* <Announcement className="small-screen-announcement" /> */}
              <div className="row">
                <div className="col-sx-12 col-6 text-sx-center d-flex align-items-center">
                  <img
                    style={{ maxHeight: '60px', marginTop: '7px' }}
                    src={require('assets/img/logo_2x.png')}
                    alt="SSO Portal"
                  />
                  <span className="environment-name-login">{env}</span>
                </div>
                <div className="col-sx-12 col-6 text-right text-sx-center">
                  <img
                    style={{ maxHeight: '70px' }}
                    className="img-fluid"
                    src={require('assets/img/TCI-PF-certificate.png')}
                    alt="TCI-PF System Cert t-passport"
                  />
                </div>
              </div>
              <p className="p-t-35">{t('forgotPassword.title')}</p>
              <Form method="post" className="p-t-15" action="#" autoComplete="off" onSubmit={handleForgotPassword}>
                <FormGroup className="form-group-default">
                  <BaseLabelCounter label={modeLogin === 'login' ? t('login.login_id') : t('profile.loginId.label')} />
                  <BaseInput
                    name="email"
                    placeholder={t('placeholder.input.Enter', {
                      field: modeLogin === 'login' ? t('login.login_id') : t('profile.loginId.label')
                    })}
                    value={email}
                    disabled={true}
                    preventAtSpecial={modeLogin !== 'login'}
                    onChange={onEmailChange}
                  />
                </FormGroup>
                <FormFeedback className="d-block">
                  {errorEmail
                    ? t(errorEmail, { label: modeLogin === 'login' ? t('login.login_id') : t('profile.loginId.label') })
                    : ''}
                </FormFeedback>
                <div>
                  <div className="m-t-2 float-left forgot-password">
                    <a href="javascript:void(0)" onClick={receiveOtpByEmail}>
                      {t('mfa.link.receive.otp.email')}
                    </a>
                  </div>
                  <br></br>
                </div>
                <div className="m-t-2 float-left forgot-password">
                  {t('common.label.already.login')}{' '}
                  <a href={`/${MODE_LOGIN.LOGIN_BY_EMAIL === modeLogin ? 'login' : 'tci'}`} onClick={backToSignIn}>
                    {t('login.signin.button')}
                  </a>
                </div>
                <div className="m-t-12">
                  <button className="btn btn-primary btn-cons m-t-12 btn-block" type="submit" disabled={countDown > 0}>
                    {t('forgotPassword.title') + (countDown > 0 ? ' ' + countDown : '')}
                  </button>
                </div>
              </Form>
              <div className="copyright-xs">
                <Copyright className="small" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {fullloading && <Loading fullscreen={true} />}
    </div>
  );
}
export default ForgotPassword;

import React, { PureComponent } from 'react';
import logo2x from '../../assets/img/logo_2x.png';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class TopNav extends PureComponent {
  static propTypes = {
    model: PropTypes.object,
    display: PropTypes.bool,
    signOut: PropTypes.func,
    user: PropTypes.object,
    nodes: PropTypes.object,
    page: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    t: PropTypes.any
  };

  static defaultProps = {
    projects: []
  };

  constructor(props) {
    super(props);
    this.state = {
      showProfileDropDown: false,
      languages: [
        {
          value: 'en_US',
          label: 'English',
          acronym: 'EN',
          img: require('assets/images/usa.png')
        },
        {
          value: 'ja_JP',
          label: '日本語',
          acronym: 'JP',
          img: require('assets/images/japan.png')
        },
        {
          value: 'zh_TW',
          label: '繁體中文',
          acronym: 'TW',
          img: require('assets/images/taiwan.png')
        }
      ],
      langCurrent: {
        acronym: 'EN',
        img: require('assets/images/usa.png')
      },
      env: ''
    };
  }

  componentDidMount = async () => {
    this.setState({ langCurrent: this.getAcronymLang(localStorage.getItem('sysLanguage')) });
    this.getCurrentEnvName();
  };

  getAcronymLang = value => {
    const { languages } = this.state;
    const _langCurrent = languages.find(o => {
      return o.value === value;
    });
    return {
      acronym: _langCurrent.acronym,
      img: _langCurrent.img
    };
  };

  changeLanguage = value => {
    const { i18n } = this.props;
    localStorage.setItem('sysLanguage', value);
    this.setState({ langCurrent: this.getAcronymLang(value) });
    i18n.changeLanguage(value);
  };

  toggleSideBar() {
    let timer;
    const body = document.body;
    const sidebarElem = this.props.nodes.current;
    const pageElem = this.props.page.current;
    const bodyColor = 'rgb(250, 250, 250)';
    pageElem.style.backgroundColor = bodyColor;
    if (body.classList.contains('sidebar-open')) {
      body.classList.remove('sidebar-open');
      timer = setTimeout(function() {
        sidebarElem.classList.remove('visible');
      }, 400);
    } else {
      clearTimeout(timer);
      sidebarElem.classList.add('visible');
      setTimeout(function() {
        body.classList.add('sidebar-open');
      }, 10);
      setTimeout(function() {
        pageElem.style.backgroundColor = '';
      }, 1000);
    }
  }

  toggleShowProfileDropDown = () => {
    const { showProfileDropDown: curr } = this.state;
    const showProfileDropDown = !curr;
    this.setState({ showProfileDropDown });
  };

  openPublishModal = () => {
    const { model } = this.props;
    model.updatePublish({
      showModal: true
    });
  };

  openBackupModal = () => {
    const { model } = this.props;
    model.updateBackup({
      showModal: true
    });
  };

  signOut = () => {
    const { signOut, t } = this.props;
    return signOut(t('signout.confirm'));
  };

  getCurrentEnvName = () => {
    const envName = process.env.REACT_APP_ENV_NAME || '';
    this.setState({ env: envName });
  };

  render() {
    const { user, t, history } = this.props;
    const { languages, langCurrent, env } = this.state;
    return (
      <div className="header">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          href="#"
          className="btn-link toggle-sidebar d-lg-none pg pg-menu"
          data-toggle="sidebar"
          onClick={this.toggleSideBar.bind(this)}
        >
          <i className="material-icons">menu</i>
        </a>
        <div className="float-left align-self-start align-self-lg-center middle-brand">
          <div className="brand inline">
            <Link to="/">
              <img src={logo2x} alt="logo" data-src={logo2x} data-src-retina={logo2x} height="60" />
            </Link>
            <span
              style={{
                color: '#D0102C',
                fontWeight: 'bold',
                fontSize: '25px',
                paddingRight: '5px',
                position: 'absolute',
                marginTop: '18px'
              }}
            >
              {env}
            </span>
          </div>
          <div className="d-block d-lg-inline project-bar" />
        </div>
        <div className="d-flex align-items-center f-1-e top-nav__right-box">
          <div className="dropdown pull-right m-r-12">
            <button
              className="lang-dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="top-nav__lang">
                <img src={langCurrent.img} className="lang-current" style={{ marginLeft: 6 }} width={25} height={17} />
              </span>
            </button>
            <div className="dropdown-menu dropdown-menu-right" role="menu">
              {languages.map((item, index) => {
                return (
                  <a
                    className="clearfix bg-master-lighter dropdown-item"
                    key={index}
                    onClick={() => this.changeLanguage(item.value)}
                  >
                    <span className="pull-left">
                      <span className="lang-menu">
                        <img src={item.img} alt={item.value} style={{ marginRight: 10 }} width={25} height={17} />
                      </span>
                      {item.label}
                    </span>
                  </a>
                );
              })}
            </div>
          </div>
          <div className="pull-left m-r-12 fs-14 font-heading d-lg-block d-none">
            <span className="semi-bold">
              {user.lastName ? `${user.lastName.trim()} ${user.firstName}` : user.firstName}
            </span>
          </div>
          <div className="dropdown pull-right">
            <button
              className="profile-dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="thumbnail-wrapper d32 circular inline">
                <img
                  src={user.profileURL ? user.profileURL : require('assets/images/avatars/user-avatar.png')}
                  alt=""
                  width="32"
                  height="32"
                />
              </span>
            </button>
            <div className="dropdown-menu dropdown-menu-right profile-dropdown" role="menu">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="clearfix bg-master-lighter dropdown-item"
                onClick={() => {
                  return history.push('/profile-settings');
                }}
              >
                <span className="pull-left">{t('common.profile.label')}</span>
                <span className="pull-right">
                  <i className="material-icons md-18">perm_identity</i>
                </span>
              </a>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="clearfix bg-master-lighter dropdown-item" onClick={this.signOut}>
                <span className="pull-left">{t('common.logout.label')}</span>
                <span className="pull-right">
                  <i className="material-icons md-18">lock</i>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(TopNav);

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseLabelCounter, TimeZoneSelect } from 'components';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router';
import { log as logService } from 'services';
import { logger } from 'core/logger';
import { errorUtils } from 'core/utils';
import { toast } from 'react-toastify';
import LogList from './LogList';
import SearchBox from 'components/SearchBox';
import { convertTextUtils } from 'core/utils';

function Log(props) {
  const { t } = useTranslation();
  const { logs, updateFullLoading, updateNotification, updateUser, addLog } = props;
  const [initialized, setInitialized] = useState(false);
  const [timezone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const history = useHistory();
  const [searchText, setSearchText] = useState('');
  const [logItems, setLogItems] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        // You can await here
        updateFullLoading(true);
        // get resources
        addLog([]);
        const logs = await logService.getLogs();
        addLog(logs);
      } catch (err) {
        logger.error('fetchData -> err', err);
        const { response } = err;
        const { data = {} } = response || {};
        let code = data.code;
        if (err.error) {
          code = err.error;
        }
        if (code === 'user-not-found') {
          toast.warning(t('common.message.user.notFound'));
        } else {
          errorUtils.handleError(err, toast, updateNotification, updateUser, history, t);
        }
      } finally {
        setInitialized(true);
        updateFullLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  useEffect(() => {
    filterLogs(logs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, logs, t]);

  const filterLogs = data => {
    const searchTextInstance = searchText
      ? searchText
          .toString()
          .slice(0)
          .trim()
      : '';
    const fullWidthSeachText = convertTextUtils.convertHalfwidthToFullwidth(searchTextInstance);
    let items = searchTextInstance.length
      ? data.filter(
          ({ sourceIp }) =>
            sourceIp &&
            (sourceIp.indexOf(searchTextInstance) > -1 ||
              (fullWidthSeachText && sourceIp.indexOf(fullWidthSeachText) > -1))
        )
      : data;
    setLogItems(items);
  };

  function handleSearch(searchText) {
    setSearchText(searchText);
  }

  return (
    <>
      <Row>
        <Col md={4} className="m-b-10 p-r-6">
          <BaseLabelCounter label={t('common.label.timezone')} required={false} />
          <TimeZoneSelect value={timezone} onChange={setTimeZone} clearable={false} />
        </Col>
        <Col md={4} className="m-b-10 p-l-6">
          <BaseLabelCounter label={t('log.label.sourceIP')} required={false} />
          <SearchBox
            placeholder={t('common.placeholder.searchBy', { field: t('log.label.sourceIP') })}
            value={searchText}
            handleSearch={searchText => handleSearch(searchText)}
          />
        </Col>
      </Row>
      <LogList t={t} logs={logItems} initialized={initialized} timezone={timezone} />
    </>
  );
}

export default Log;

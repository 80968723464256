import badgeApple from 'assets/images/apps/apple-store-badge.png';
import authenticatorStep0201 from 'assets/images/apps/authenticator_step_0201.jpg';
import authenticatorStep0202 from 'assets/images/apps/authenticator_step_0202.jpg';
import authenticatorStep0301 from 'assets/images/apps/authenticator_step_0301.jpg';
import authenticatorStep0302 from 'assets/images/apps/authenticator_step_0302.jpg';
import badgeGoogle from 'assets/images/apps/google-play-badge.png';
import qrAppleStore from 'assets/images/apps/qr-apple-store.png';
import qrPlayStore from 'assets/images/apps/qr-play-store.png';
import 'assets/scss/theme/login.scss';
import { BaseInput, BaseLabelCounter, BaseSelect, BaseSwitch, RadioList } from 'components';
import {
  ACCEPTED_DIAL_CODES,
  AUTHENTICATOR_APP_NAME,
  COUNTRY_CODES,
  MFA_METHODS,
  MOBILE_PHONE_MAX_LENGTH_BY_COUNTRY
} from 'core/constants';
import { logger } from 'core/logger';
import { errorUtils } from 'core/utils';
import validator from 'core/validator/customValidator';
import { options } from 'i18next';
import { isEmpty, trimStart } from 'lodash';
// Authenticator QR (S)
import { authenticator } from 'otplib';
import QRCode from 'qrcode';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Button, Col, Form, FormFeedback, FormText, Row } from 'reactstrap';
import { resource as resourceService, user as userService } from 'services';
import MFA from '../MFA';
import './index.scss';
// Authenticator QR (E)
let smsTimer = null,
  emailTimer = null;

function TwoStepAuthentication(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { updateFullLoading, currentUser: originalUser, updateUser, updateNotification, setLoginIdStore } = props;
  const { isReal = true, enableMFAAdmin, mfaSMS } = originalUser;
  const [view, setView] = useState('SETTING');
  const [mfaStatus, setMfaStatus] = useState(originalUser.mfa);
  const [selectedMethod, setSelectedMethod] = useState(originalUser.mfaMethod);
  const [contactEmailStep, setContactEmailStep] = useState(1);
  const [authenticatorStep, setAuthenticatorStep] = useState(1);
  const [mobileStep, setMobileStep] = useState(1);
  const [contactEmail, setContactEmail] = useState(originalUser.contactEmail);
  const [errorContactEmail, setErrorContactEmail] = useState('');
  const [mobilePhone, setMobilePhone] = useState(originalUser.mobilePhone);
  const [mobileCountryCode, setMobileCountryCode] = useState(originalUser.mobileCountryCode);
  const [errorMobileCountryCode, setErrorMobileCountryCode] = useState('');
  const [errorMobilePhone, setErrorMobilePhone] = useState('');
  const [sendSmsOtpCodeCountDown, setSendSmsOtpCodeCountDown] = useState(0);
  const [sendEmailOtpCodeCountDown, setSendEmailOtpCodeCountDown] = useState(0);
  const [otp, setOTP] = useState('');
  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  const shouldDisabledMFA = !!enableMFAAdmin;

  // Authenticator QR (S)
  const [authenticatorSecret, setAuthenticatorSecret] = useState(originalUser.authenticatorSecret);
  const [authenticatorQR, setAuthenticatorQR] = useState('');
  // Authenticator QR (E)

  const generateQR = () => {
    // generate new pr-code
    const authenticatorUser = {
      loginId: originalUser.loginId,
      otpSecret: authenticator.generateSecret(10)
    };
    console.log('authenticatorUser', authenticatorUser);
    // set authenticator secret
    setAuthenticatorSecret(authenticatorUser.otpSecret);
    // generate QR
    const otpAuth = authenticator.keyuri(
      authenticatorUser.loginId,
      AUTHENTICATOR_APP_NAME,
      authenticatorUser.otpSecret
    );
    QRCode.toDataURL(otpAuth, (error, url) => {
      if (error) {
        console.log(error);
        toast.warning(t('common.message.generate.qrCode.failed'));
      } else {
        setAuthenticatorQR(url);
      }
    });
  };

  const verifyAuthenticatorOTP = async () => {
    setOtpErrorMessage(!otp ? 'common.validator.isRequired' : '');
    if (!otp) return toast.warning(t('common.message.invalidInputs'));
    // process save authenticator secret to database
    updateFullLoading(true);
    try {
      await userService.updateAuthenticatorConfig({ loginId: originalUser.loginId, authenticatorSecret, otp });
      const newUserInfo = await userService.getUser();
      setAuthenticatorSecret(newUserInfo.authenticatorSecret);
      updateUser(newUserInfo);
      toast.success(t('common.message.auth.config.success'));
      setAuthenticatorStep(authenticatorStep + 1);
      setSelectedMethod(MFA_METHODS.AUTHENTICATOR);
      // display resource ( case: setup MFA in Policy )
      const resources = await resourceService.getResources();
      props.addResources({ initialized: true, data: resources });
    } catch (error) {
      const { response } = error;
      const { data = {} } = response || {};
      const code = data.code;
      switch (code) {
        case 'auth/user-not-found':
        case 'user-not-found':
          toast.warning(t('common.message.user.notFound'));
          break;
        case 'account-is-locked':
          toast.warning(t('common.message.user.locked'));
          break;
        case 'invalid-otp':
          const mfaRetry = localStorage.getItem('mfaRetry') || 0;
          const count = parseInt(mfaRetry);
          if (count >= 5) {
            onCloseAuthenticatorPanel();
          } else {
            localStorage.setItem('mfaRetry', count + 1);
          }
          toast.warning(t('mfa.message.invalid.otp'));
          break;
        case 'invalid-input-data':
          toast.warning(t('common.message.invalidInputs'));
          break;
        default:
          errorUtils.handleError(error, toast, updateNotification, updateUser, history, t);
      }
    } finally {
      updateFullLoading(false);
    }
  };

  const verifyMobilePhone = async () => {
    if (validateMobilePhone()) {
      updateFullLoading(true);
      // generate otp
      await userService
        .generateTwoStepAuthenticationOTP({
          lng: localStorage.getItem('sysLanguage') || 'en_US',
          loginId: originalUser.loginId,
          otpPhase: 'SETUP',
          otpMethod: MFA_METHODS.SMS,
          mobilePhone,
          mobileCountryCode
        })
        .then(() => {
          localStorage.setItem('selectedMethod', MFA_METHODS.SMS);
          setSendOtpCodeTimer(MFA_METHODS.SMS);
          setMobileStep(mobileStep + 1);
        })
        .catch(error => {
          const { response } = error;
          const { data = {} } = response || {};
          const code = data.code;
          let warningMessage = '';
          switch (code) {
            case 'auth/user-not-found':
            case 'user-not-found':
              warningMessage = t('common.message.user.notFound');
              break;
            case 'account-is-locked':
              warningMessage = t('common.message.user.locked');
              break;
            case 'too-many-sms':
              warningMessage = t('mfa.message.too.many.sms');
              break;
            case 'country-not-support-sms':
              warningMessage = t('common.message.mobile.not.support.country');
              break;
            default:
              errorUtils.handleError(error, toast, updateNotification, updateUser, history, t);
          }
          if (warningMessage) {
            toast.warning(warningMessage);
          }
        })
        .finally(() => updateFullLoading(false));
    }
  };

  const verifyContactEmail = async () => {
    if (validateEmail()) {
      updateFullLoading(true);
      // generate otp
      await userService
        .generateTwoStepAuthenticationOTP({
          lng: localStorage.getItem('sysLanguage') || 'en_US',
          loginId: originalUser.loginId,
          otpPhase: 'SETUP',
          otpMethod: MFA_METHODS.EMAIL,
          contactEmail
        })
        .then(() => {
          localStorage.setItem('selectedMethod', MFA_METHODS.EMAIL);
          setSendOtpCodeTimer(MFA_METHODS.EMAIL);
          setContactEmailStep(contactEmailStep + 1);
        })
        .catch(error => {
          const { response } = error;
          const { data = {} } = response || {};
          const code = data.code;
          let warningMessage = '';
          switch (code) {
            case 'auth/user-not-found':
            case 'user-not-found':
              warningMessage = t('common.message.user.notFound');
              break;
            case 'account-is-locked':
              warningMessage = t('common.message.user.locked');
              break;
            default:
              errorUtils.handleError(error, toast, updateNotification, updateUser, history, t);
          }
          if (warningMessage) {
            toast.warning(warningMessage);
          }
        })
        .finally(() => updateFullLoading(false));
    }
  };

  const onChangeOTP = value => {
    let error = '';
    if (!value) {
      error = 'common.validator.isRequired';
    }
    setOTP(value);
  };

  const onCloseContactEmailPanel = () => {
    setContactEmailStep(1);
    setContactEmail(originalUser.contactEmail);
    setErrorContactEmail('');
    setView('SETTING');
  };

  const onCloseAuthenticatorPanel = () => {
    setAuthenticatorStep(1);
    setAuthenticatorQR(null);
    setAuthenticatorSecret(originalUser.authenticatorSecret);
    setOTP('');
    setView('SETTING');
  };

  const onCloseMobilePanel = () => {
    setMobileStep(1);
    setMobilePhone(originalUser.mobilePhone);
    setMobileCountryCode(originalUser.mobileCountryCode);
    setErrorMobileCountryCode('');
    setErrorMobilePhone('');
    setView('SETTING');
  };

  const onBack = async selectedMethod => {
    // update new mobile phone & dial code
    const updatedUser = await userService.getUser();
    if (MFA_METHODS.SMS === selectedMethod) {
      setMobilePhone(updatedUser.mobilePhone);
      setMobileCountryCode(updatedUser.mobileCountryCode);
    }
    updateUser(updatedUser);
    localStorage.removeItem('mfaRetry');
    setSelectedMethod(selectedMethod);
    setView('SUCCESS');
  };

  const onCancel = () => {
    localStorage.removeItem('mfaRetry');
    setView('SETTING');
  };

  const resetForm = () => {
    setContactEmail(originalUser.contactEmail);
    setMobilePhone(originalUser.mobilePhone);
    setMfaStatus(originalUser.mfa);
    setSelectedMethod(originalUser.mfaMethod);
    setErrorContactEmail('');
    setErrorMobilePhone('');
    setErrorMobileCountryCode('');
    setOtpErrorMessage('');
    setView('SETTING');
    localStorage.removeItem('mfaRetry');
  };

  // clear otp error message when change step
  useEffect(() => {
    setOtpErrorMessage('');
  }, [authenticatorStep]);

  useEffect(() => {
    setOtpErrorMessage(!otp ? 'common.validator.isRequired' : '');
  }, [otp]);

  const validateEmail = () => {
    let result = true;
    if (!contactEmail) {
      setErrorContactEmail('common.validator.isRequired');
      result = false;
    } else if (!validator.isCorrectEmail(contactEmail)) {
      setErrorContactEmail('common.validator.invalidEmail');
      result = false;
    } else if (contactEmail.toLowerCase().trim() === originalUser.loginId) {
      setErrorContactEmail('profile.message.loginId.mustBeDifferent.contactEmail');
      result = false;
    }
    return result;
  };

  const validateMobilePhone = () => {
    let result = true,
      errorMessage = '';
    // validate mobile country code
    if (!mobileCountryCode) {
      result = false;
      setErrorMobileCountryCode(['common.validator.isRequiredSelect', { label: 'profile.country.code.label' }]);
    } else {
      setErrorMobileCountryCode('');
    }
    // validate phone number
    if (!mobilePhone) {
      result = false;
      errorMessage = ['common.validator.isRequired', { label: 'profile.mobile.phone.label' }];
    } else if (!validator.isPhoneNumber(mobilePhone)) {
      result = false;
      errorMessage = ['common.validator.isPhoneNumber'];
    } else if (Object.values(ACCEPTED_DIAL_CODES).includes(mobileCountryCode)) {
      let acceptedStartWith = [];
      const limitLength = MOBILE_PHONE_MAX_LENGTH_BY_COUNTRY[mobileCountryCode];
      switch (mobileCountryCode) {
        case 'JP':
          acceptedStartWith = ['070', '70', '080', '80', '090', '90'];
          break;
        // case 'SG':
        //   acceptedStartWith = ['8', '9'];
        //   break;
        // case 'TH':
        //   acceptedStartWith = ['6', '8', '9', '06', '08', '09'];
        //   break;
        // case 'ID':
        //   acceptedStartWith = ['8', '08'];
        //   break;
        // case 'MY':
        //   acceptedStartWith = ['1', '01'];
        //   break;
        // case 'PH':
        //   acceptedStartWith = ['89', '9', '089', '09'];
        //   break;
        // case 'TW':
        //   acceptedStartWith = ['9', '09'];
        //   break;
        // case 'VN':
        //   acceptedStartWith = ['3', '5', '7', '8', '9', '03', '05', '07', '08', '09'];
        //   break;
        default:
          break;
      }
      let isAcceptedStartWith = true;
      if (acceptedStartWith.length > 0)
        isAcceptedStartWith = acceptedStartWith.some(startNumber => mobilePhone.startsWith(startNumber));
      if (!isAcceptedStartWith) {
        result = false;
        errorMessage = ['profile.country.code.must.be', { numbers: acceptedStartWith.join(', ') }];
      } else if (trimStart(mobilePhone, '0').toString().length > limitLength) {
        result = false;
        errorMessage = ['common.validator.max.length', { maxLength: limitLength }];
      } else {
        setErrorMobilePhone('');
      }
    }
    setErrorMobilePhone(errorMessage);
    return result;
  };

  useEffect(() => {
    if ('SETUP_MOBILE' === view) {
      validateMobilePhone();
    }
  }, [mobilePhone, mobileCountryCode]);

  useEffect(() => {
    const _maxLengthPhoneNumber = MOBILE_PHONE_MAX_LENGTH_BY_COUNTRY[mobileCountryCode];
    if (mobilePhone) {
      setMobilePhone(mobilePhone.slice(0, _maxLengthPhoneNumber));
    }
  }, [mobileCountryCode]);

  const _renderValidatorError = error => {
    if (isEmpty(error)) return '';
    if (error.length > 1 && error[1].label) return t(error[0], { label: t(error[1].label) });
    return t(...error);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      updateFullLoading(true);
      if (mfaStatus && !selectedMethod) {
        toast.warning(t('mfa.message.required.select.method'));
        return;
      }
      // Update profile
      const updatingData = {
        loginId: originalUser.loginId,
        mfa: enableMFAAdmin ? originalUser.mfa : mfaStatus
      };
      await userService.updateTwoStepAuthenticationInfo(updatingData);
      // get updated info and fill back
      const updatedUser = await userService.getUser();
      console.log('🚀 updatedUser -> ', updatedUser);
      setMfaStatus(updatedUser.mfa);
      setSelectedMethod(updatedUser.mfaMethod);
      updateUser(updatedUser);
      // display resource ( case: setup MFA in Policy )
      const resources = await resourceService.getResources();
      props.addResources({ initialized: true, data: resources });
      // show success message
      toast.success(t('profile.message.updated.ok'));
    } catch (error) {
      logger.error('handleSubmit -> error', error);
      const { response } = error;
      const { data = {} } = response || {};
      const code = data.code;
      let warningMessage = '';
      switch (code) {
        case 'auth/user-not-found':
        case 'user-not-found':
          warningMessage = t('common.message.user.notFound');
          break;
        case 'account-is-locked':
          warningMessage = t('common.message.user.locked');
          break;
        default:
          errorUtils.handleError(error, toast, updateNotification, updateUser, history, t);
      }
      if (warningMessage) {
        toast.warning(warningMessage);
      }
    } finally {
      updateFullLoading(false);
    }
  };

  const _renderVerifyOTPLayout = () => {
    const method = localStorage.getItem('selectedMethod');
    let content = '';
    // build messages
    switch (method) {
      case MFA_METHODS.EMAIL:
        content = t('mfa.otp.content.email');
        break;
      case MFA_METHODS.AUTHENTICATOR:
        content = t('mfa.otp.content.authenticator');
        break;
      case MFA_METHODS.SMS:
        content = t('mfa.otp.content.sms');
        break;
      default:
    }
    return (
      <div className="row justify-content-center">
        <div className="col-12 col-lg-9 xs-m-l-r-0" style={{ marginTop: '10px', marginLeft: '30px' }}>
          <p className={''} dangerouslySetInnerHTML={{ __html: content }} />
          <MFA
            history={history}
            processInfo={{
              otpPhase: 'CHANGE',
              otpMethod: method,
              contactEmail,
              mobilePhone,
              mobileCountryCode
            }}
            handleCancel={onCancel}
            handleBack={onBack}
          />
        </div>
      </div>
    );
  };

  const _renderSuccessLayout = () => {
    let content = '';
    // build messages
    switch (selectedMethod) {
      case MFA_METHODS.EMAIL:
        content = t('mfa.otp.success.email');
        break;
      case MFA_METHODS.AUTHENTICATOR:
        content = t('mfa.otp.success.authenticator');
        break;
      case MFA_METHODS.SMS:
        content = t('mfa.otp.success.sms');
        break;
      default:
    }
    return (
      <div className="row justify-content-center mfa-confirm-btns">
        <div className="col-12 col-lg-9 xs-m-l-r-0" style={{ marginTop: '10px', marginLeft: '30px' }}>
          <p className={''} dangerouslySetInnerHTML={{ __html: content }} />
          <Button color="default" variant="contained" className="m-t-20" onClick={() => setView('SETTING')}>
            {t('Common.Button.Close')}
          </Button>
        </div>
      </div>
    );
  };

  const _renderContactEmailLayout = () => {
    const step = contactEmailStep;
    return (
      <div className="row justify-content-center">
        <div className="col-12 col-lg-9 xs-m-l-r-0" style={{ marginTop: '10px', marginLeft: '30px' }}>
          <h2>{t('common.step.header', { header: t('common.header.contactEmail'), step, total: 3 })}</h2>
          {step === 1 && (
            <div id="container" className="m-t-30">
              <Row>
                <Col md="12" className="text-left m-dis-ib" style={{ display: 'inline-flex' }}>
                  <div className="text-lg-right m-t-5">
                    <BaseLabelCounter
                      label={t('profile.contactEmail.label')}
                      value={contactEmail}
                      length={320}
                      required={true}
                    />
                  </div>
                  <div className="col-12 col-lg-9">
                    <BaseInput
                      type="text"
                      maxLength={320}
                      placeholder={t('placeholder.input.Enter', { field: t('profile.contactEmail.label') })}
                      value={contactEmail ? contactEmail : ''}
                      onChange={value => {
                        if (value) {
                          setErrorContactEmail('');
                        }
                        setContactEmail(value);
                      }}
                      disabled={!originalUser.isEditUserProfile}
                    />
                    <FormText>{t('common.email.hintText')}</FormText>
                    <FormFeedback className="d-block">
                      {errorContactEmail ? t(errorContactEmail, { label: t('profile.contactEmail.label') }) : ''}
                    </FormFeedback>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6" className="text-left mfa-confirm-btns">
                  <Button color="default" className="m-t-20" onClick={onCloseContactEmailPanel}>
                    {t('Common.Button.Close')}
                  </Button>
                  <Button
                    color="primary"
                    className="m-t-20 m-l-6"
                    onClick={verifyContactEmail}
                    disabled={contactEmail === originalUser.contactEmail || sendEmailOtpCodeCountDown > 0}
                  >
                    {t('common.button.next')}
                    {sendEmailOtpCodeCountDown > 0 && (
                      <span> ({t('label.count.seconds.left', { time: sendEmailOtpCodeCountDown })})</span>
                    )}
                  </Button>
                </Col>
              </Row>
            </div>
          )}
          {step === 2 && (
            <div id="container">
              <Row>
                <Col md={12} className="text-color-sub">
                  <p className={''} dangerouslySetInnerHTML={{ __html: t('mfa.contactEmail.content.step.2') }} />
                </Col>
              </Row>
              <Row>
                <Col md="12" className="text-left">
                  <MFA
                    history={history}
                    processInfo={{
                      otpPhase: 'SETUP',
                      otpMethod: MFA_METHODS.EMAIL,
                      contactEmail
                    }}
                    handleCancel={() => {
                      onCancel();
                      onCloseContactEmailPanel();
                    }}
                    handleBack={onBack}
                  />
                </Col>
              </Row>
            </div>
          )}
          {step === 3 && (
            <div id="container">
              <Row>
                <Col md={12} className="text-color-sub">
                  <p className={''} dangerouslySetInnerHTML={{ __html: t('mfa.contactEmail.content.step.3') }} />
                </Col>
              </Row>
              <Row>
                <Col md="6" className="text-left mfa-confirm-btns">
                  <Button color="default" className="m-t-20" onClick={onCloseContactEmailPanel}>
                    {t('Common.Button.Close')}
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    );
  };

  const _renderAuthenticatorLayout = () => {
    const step = authenticatorStep;
    return (
      <div className="row justify-content-center">
        <div className="col-12 col-lg-9 xs-m-l-r-0" style={{ marginTop: '10px', marginLeft: '30px' }}>
          <h2>{t('common.step.header', { header: t('common.header.authenticator'), step, total: 5 })}</h2>
          {step === 1 && (
            <div id="container">
              <Row>
                <Col md={12} className="text-color-sub">
                  <p className={''} dangerouslySetInnerHTML={{ __html: t('mfa.authenticator.content.step.1') }} />
                </Col>
              </Row>
              <Row style={{ width: '500px' }} className="m-w-100">
                <Col md={6} className="text-center">
                  <img src={badgeApple} height="50px" alt={t('mfa.download.apple')} />
                  <img src={qrAppleStore} width="100%" alt={t('mfa.download.apple.qr')} />
                </Col>
                <Col md={6} className="text-center">
                  <img src={badgeGoogle} height="50px" alt={t('mfa.download.google')} />
                  <img src={qrPlayStore} width="100%" alt={t('mfa.download.google.qr')} />
                </Col>
              </Row>
              <Row>
                <Col md="6" className="text-left mfa-confirm-btns">
                  <Button color="default" className="m-t-20" onClick={onCloseAuthenticatorPanel}>
                    {t('Common.Button.Close')}
                  </Button>
                  <Button color="primary" className="m-t-20 m-l-6" onClick={() => setAuthenticatorStep(step + 1)}>
                    {t('common.button.next')}
                  </Button>
                </Col>
              </Row>
            </div>
          )}
          {step === 2 && (
            <div id="container">
              <Row>
                <Col md={12} className="text-color-sub">
                  <p className={''} dangerouslySetInnerHTML={{ __html: t('mfa.authenticator.content.step.2') }} />
                </Col>
              </Row>
              <Row style={{ width: '500px' }} className="xs-full-width">
                <Col md={6} className="text-center">
                  <img src={authenticatorStep0201} width="100%" alt="authenticatorStep0201" />
                </Col>
                <Col md={6} className="text-center">
                  <img src={authenticatorStep0202} width="100%" alt="authenticatorStep0202" />
                </Col>
              </Row>
              <Row>
                <Col md="6" className="text-left mfa-confirm-btns">
                  <Button color="default" className="m-t-20" onClick={onCloseAuthenticatorPanel}>
                    {t('Common.Button.Close')}
                  </Button>
                  <Button color="default" className="m-t-20 m-l-6" onClick={() => setAuthenticatorStep(step - 1)}>
                    {t('common.label.prev')}
                  </Button>
                  <Button color="primary" className="m-t-20 m-l-6" onClick={() => setAuthenticatorStep(step + 1)}>
                    {t('common.button.next')}
                  </Button>
                </Col>
              </Row>
            </div>
          )}
          {step === 3 && (
            <div id="container">
              <Row>
                <Col md={12} className="text-color-sub">
                  <p className={''} dangerouslySetInnerHTML={{ __html: t('mfa.authenticator.content.step.3') }} />
                </Col>
              </Row>
              <Row style={{ width: '500px' }} className="xs-full-width">
                <Col md={6} className="text-center">
                  <img src={authenticatorStep0301} width="100%" alt="authenticatorStep0301" />
                </Col>
                <Col md={6} className="text-center">
                  <img src={authenticatorStep0302} width="100%" alt="authenticatorStep0302" />
                </Col>
              </Row>
              <Row>
                <Col md="6" className="text-left mfa-confirm-btns">
                  <Button color="default" className="m-t-20" onClick={onCloseAuthenticatorPanel}>
                    {t('Common.Button.Close')}
                  </Button>
                  <Button color="default" className="m-t-20 m-l-6" onClick={() => setAuthenticatorStep(step - 1)}>
                    {t('common.label.prev')}
                  </Button>
                  <Button color="primary" className="m-t-20 m-l-6" onClick={() => setAuthenticatorStep(step + 1)}>
                    {t('common.button.next')}
                  </Button>
                </Col>
              </Row>
            </div>
          )}
          {step === 4 && (
            <div id="container">
              <Row>
                <Col md={12} className="text-color-sub">
                  <span>{t('mfa.authenticate.message')}</span>
                  <br />
                </Col>
              </Row>
              <Row style={{ width: '300px' }} className="xs-full-width">
                <Col md={12} className="text-center">
                  <img src={authenticatorQR} width="100%" alt="authenticatorQR" />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="text-color-sub">
                  <p className={''} dangerouslySetInnerHTML={{ __html: t('mfa.authenticator.content.step.4') }} />
                </Col>
              </Row>
              <Row>
                <Col className="inline">
                  <BaseLabelCounter label={t('mfa.otp.label')} value={otp} length={6} required={true} />
                  <Form
                    className="submit-mfa"
                    action="#"
                    autoComplete="off"
                    onSubmit={e => {
                      verifyAuthenticatorOTP();
                      e.preventDefault();
                    }}
                  >
                    <BaseInput
                      name="otp"
                      placeholder={t('placeholder.input.Enter', { field: t('mfa.otp.label') })}
                      value={otp}
                      onChange={onChangeOTP}
                      maxLength={6}
                      customizeRegex={{ otp: /^[0-9０-９]*$/ }}
                      autoFocus={true}
                    />
                  </Form>
                  <FormFeedback className="d-block">
                    {otpErrorMessage ? t(otpErrorMessage, { label: t('mfa.otp.label') }) : ''}
                  </FormFeedback>
                </Col>
              </Row>
              <Row>
                <Col md="6" className="text-left mfa-confirm-btns">
                  <Button color="default" className="m-t-20" onClick={onCloseAuthenticatorPanel}>
                    {t('Common.Button.Close')}
                  </Button>
                  <Button color="default" className="m-t-20 m-l-6" onClick={() => setAuthenticatorStep(step - 1)}>
                    {t('common.label.prev')}
                  </Button>
                  <Button color="primary" className="m-t-20 m-l-6" onClick={() => verifyAuthenticatorOTP()}>
                    {t('common.button.next')}
                  </Button>
                </Col>
              </Row>
            </div>
          )}
          {step === 5 && (
            <div id="container">
              <Row>
                <Col md={12} className="text-color-sub">
                  <p className={''} dangerouslySetInnerHTML={{ __html: t('mfa.authenticator.content.step.5') }} />
                </Col>
              </Row>
              <Row>
                <Col md="6" className="text-left mfa-confirm-btns">
                  <Button color="default" className="m-t-20" onClick={onCloseAuthenticatorPanel}>
                    {t('Common.Button.Close')}
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    );
  };

  const _renderMobileLayout = () => {
    const step = mobileStep;
    // get dial codes
    const dialCodes = Object.keys(COUNTRY_CODES).map(code => ({
      value: code,
      label: `${COUNTRY_CODES[code].dialCode} ${COUNTRY_CODES[code].countryName}`
    }));
    const limitLength = MOBILE_PHONE_MAX_LENGTH_BY_COUNTRY[mobileCountryCode];
    // parse mobile phone
    let _mobilePhone = '',
      _countryCode = mobileCountryCode;
    if (mobilePhone) {
      if (Object.keys(ACCEPTED_DIAL_CODES).some(code => mobilePhone.startsWith(code))) {
        _countryCode = ACCEPTED_DIAL_CODES[mobilePhone.substr(0, 3)];
        _mobilePhone = mobilePhone.substr(3);
      } else {
        _mobilePhone = mobilePhone;
        _countryCode = mobileCountryCode;
      }
      _mobilePhone = _mobilePhone.replace('-', '');
      if (_mobilePhone) {
        _mobilePhone = mobilePhone.slice(0, limitLength);
      }
    }
    return (
      <div className="row justify-content-center">
        <div className="col-12 col-lg-9 xs-m-l-r-0" style={{ marginTop: '10px', marginLeft: '30px' }}>
          <h2>{t('common.step.header', { header: t('common.header.mobile'), step, total: 3 })}</h2>
          {/* <h2>Mobile - Step {step}/3</h2> */}
          {step === 1 && (
            <div id="container" className="m-t-30">
              <Row>
                <Col md="12" className="text-left m-dis-ib" style={{ display: 'inline-flex' }}>
                  <div className="m-t-5">
                    <BaseLabelCounter
                      label={t('profile.mobile.phone.label')}
                      value={mobilePhone}
                      length={limitLength}
                      required={mfaStatus && MFA_METHODS.SMS === selectedMethod}
                    />
                  </div>
                  <div style={{ width: '200px' }} className="m-l-5 m-w-100 m-m-l-0">
                    <BaseSelect
                      value={_countryCode}
                      options={dialCodes}
                      placeholder={t('common.placeholder.select', {
                        field: t('profile.country.code.label')
                      })}
                      onChange={value => setMobileCountryCode(value)}
                      allowSort={false}
                    />
                    <FormFeedback className="d-block">{_renderValidatorError(errorMobileCountryCode)}</FormFeedback>
                  </div>
                  <div className="m-l-5 m-m-l-0 mfa-sms-form-step1">
                    <BaseInput
                      style={{ height: '38px' }}
                      maxLength={limitLength}
                      placeholder={t('placeholder.input.Enter', { field: t('profile.mobile.phone.label') })}
                      value={_mobilePhone}
                      onChange={value => setMobilePhone(value)}
                      customizeRegex={{ mobilePhone: /^[0-9０-９]*$/ }}
                    />
                    <FormText>
                      {mobileCountryCode ? t(`phone.number.${mobileCountryCode.toLocaleLowerCase()}.hint.text`) : ''}
                    </FormText>
                    <FormFeedback className="d-block">{_renderValidatorError(errorMobilePhone)}</FormFeedback>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6" className="text-left mfa-confirm-btns">
                  <Button color="default" className="m-t-20" onClick={onCloseMobilePanel}>
                    {t('Common.Button.Close')}
                  </Button>
                  <Button
                    color="primary"
                    className="m-t-20 m-l-5"
                    onClick={verifyMobilePhone}
                    disabled={sendSmsOtpCodeCountDown > 0}
                  >
                    {t('common.button.next')}{' '}
                    {sendSmsOtpCodeCountDown > 0 && t('label.count.seconds.left', { time: sendSmsOtpCodeCountDown })}
                  </Button>
                </Col>
              </Row>
            </div>
          )}
          {step === 2 && (
            <div id="container">
              <Row>
                <Col md={12} className="text-color-sub">
                  <p className={''} dangerouslySetInnerHTML={{ __html: t('mfa.sms.content.step.2') }} />
                </Col>
              </Row>
              <Row>
                <Col md="12" className="text-left">
                  <MFA
                    history={history}
                    processInfo={{
                      otpPhase: 'SETUP',
                      otpMethod: MFA_METHODS.SMS,
                      contactEmail,
                      mobilePhone,
                      mobileCountryCode
                    }}
                    handleCancel={() => {
                      onCancel();
                      onCloseMobilePanel();
                    }}
                    handleBack={onBack}
                  />
                </Col>
              </Row>
            </div>
          )}
          {step === 3 && (
            <div id="container">
              <Row>
                <Col md={12} className="text-color-sub">
                  <p className={''} dangerouslySetInnerHTML={{ __html: t('mfa.sms.content.step.3') }} />
                </Col>
              </Row>
              <Row>
                <Col md="6" className="text-left mfa-confirm-btns">
                  <Button color="default" className="m-t-20" onClick={onCloseMobilePanel}>
                    {t('Common.Button.Close')}
                  </Button>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    // reset mfaRetry
    localStorage.removeItem('mfaRetry');
    setOtpCountDown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sendSmsOtpCodeCountDown > 0) {
      smsTimer = setInterval(() => {
        setSendSmsOtpCodeCountDown(sendSmsOtpCodeCountDown - 1);
      }, 1000);
    } else {
      clearInterval(smsTimer);
      localStorage.removeItem('sendSmsOtpCodeTimestamp');
    }
    return () => clearInterval(smsTimer);
  }, [sendSmsOtpCodeCountDown]);

  useEffect(() => {
    if (sendEmailOtpCodeCountDown > 0) {
      emailTimer = setInterval(() => {
        setSendEmailOtpCodeCountDown(sendEmailOtpCodeCountDown - 1);
      }, 1000);
    } else {
      clearInterval(emailTimer);
      localStorage.removeItem('sendEmailOtpCodeTimestamp');
    }
    return () => clearInterval(emailTimer);
  }, [sendEmailOtpCodeCountDown]);

  const getSendSmsOtpCodeTimer = () => {
    const _sendSmsOtpCodeTimestamp = localStorage.getItem('sendSmsOtpCodeTimestamp');
    const _now = Math.round(Date.now() / 1000);
    return _sendSmsOtpCodeTimestamp - _now + 30;
  };

  const getSendEmailOtpCodeTimer = () => {
    const _sendSmsOtpCodeTimestamp = localStorage.getItem('sendEmailOtpCodeTimestamp');
    const _now = Math.round(Date.now() / 1000);
    return _sendSmsOtpCodeTimestamp - _now + 30;
  };

  const setSendOtpCodeTimer = mfaMethod => {
    const sendOtpCodeTimestamp = Math.round(Date.now() / 1000);
    const _now = Math.round(Date.now() / 1000);
    const ttl = sendOtpCodeTimestamp - _now + 30;
    if (MFA_METHODS.SMS === mfaMethod) {
      localStorage.setItem('sendSmsOtpCodeTimestamp', sendOtpCodeTimestamp);
      setSendSmsOtpCodeCountDown(ttl);
    } else if (MFA_METHODS.EMAIL === mfaMethod) {
      localStorage.setItem('sendEmailOtpCodeTimestamp', sendOtpCodeTimestamp);
      setSendEmailOtpCodeCountDown(ttl);
    }
  };

  const setOtpCountDown = () => {
    const _getSendSmsOtpCodeTimer = getSendSmsOtpCodeTimer();
    const _getSendEmailOtpCodeTimer = getSendEmailOtpCodeTimer();
    setSendSmsOtpCodeCountDown(_getSendSmsOtpCodeTimer);
    setSendEmailOtpCodeCountDown(_getSendEmailOtpCodeTimer);
  };

  const onMethodChange = async method => {
    if (method) {
      try {
        updateFullLoading(true);
        localStorage.setItem('selectedMethod', method);
        setLoginIdStore(originalUser.loginId);
        await userService.generateTwoStepAuthenticationOTP({
          lng: localStorage.getItem('sysLanguage') || 'en_US',
          loginId: originalUser.loginId,
          otpPhase: 'CHANGE',
          otpMethod: method,
          contactEmail,
          mobilePhone,
          mobileCountryCode
        });
        updateFullLoading(false);
        setSendOtpCodeTimer(method);
        setView('VERIFY_OTP');
      } catch (error) {
        updateFullLoading(false);
        const { response } = error;
        const { data = {} } = response || {};
        const code = data.code;
        let warningMessage = '';
        switch (code) {
          case 'auth/user-not-found':
          case 'user-not-found':
            warningMessage = t('common.message.user.notFound');
            break;
          case 'account-is-locked':
            warningMessage = t('common.message.user.locked');
            break;
          case 'too-many-sms':
            warningMessage = t('mfa.message.too.many.sms');
            break;
          case 'country-not-support-sms':
            warningMessage = t('common.message.mobile.not.support.country');
            break;
          default:
            errorUtils.handleError(error, toast, updateNotification, updateUser, history, t);
        }
        if (warningMessage) {
          toast.warning(warningMessage);
        }
      }
    }
  };

  const _renderSettingLayout = () => {
    // parse mobile phone
    let _mobilePhone = '',
      _countryCode = COUNTRY_CODES[mobileCountryCode]
        ? `${COUNTRY_CODES[mobileCountryCode].dialCode} ${COUNTRY_CODES[mobileCountryCode].countryName}`
        : '';
    if (mobilePhone) {
      if (Object.keys(ACCEPTED_DIAL_CODES).some(code => mobilePhone.startsWith(code))) {
        _countryCode = mobilePhone.substr(0, 3);
        _mobilePhone = mobilePhone.substr(3);
      } else {
        _mobilePhone = mobilePhone;
        _countryCode = COUNTRY_CODES[mobileCountryCode]
          ? `${COUNTRY_CODES[mobileCountryCode].dialCode} ${COUNTRY_CODES[mobileCountryCode].countryName}`
          : '';
      }
      _mobilePhone = _mobilePhone.replace('-', '');
    }

    let optionMFAs = [
      {
        value: MFA_METHODS.EMAIL,
        label: (
          <Row>
            <Col md={5}>
              <span className="m-l-5 m-dis-ib">{t('login.login_id')}</span>
              {sendEmailOtpCodeCountDown > 0 && (
                <span> ({t('label.count.seconds.left', { time: sendEmailOtpCodeCountDown })})</span>
              )}
            </Col>
            {!isReal && (
              <Col md={7} className="mr-top-4">
                <div className="row">
                  <div className="col-lg-9">
                    <div className="btn-group m-dis-ib xs-full-width">
                      <button
                        className="btn btn-info m-r-10 xs-full-width"
                        type="button"
                        onClick={() => {
                          setContactEmailStep(1);
                          setView('SETUP_CONTACT_EMAIL');
                        }}
                      >
                        {t('common.label.contactEmail.configuration')}
                      </button>
                    </div>
                    <span className="m-t-5 m-dis-ib">
                      ({contactEmail ? t('common.label.status.configured') : t('common.label.status.not.configured')})
                    </span>
                    {contactEmail && (
                      <div style={{ width: '350px' }} className="m-t-5">
                        <BaseInput disabled value={contactEmail} />
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            )}
          </Row>
        ),
        disabled: (!isReal && !contactEmail) || sendEmailOtpCodeCountDown > 0
      },
      {
        value: MFA_METHODS.AUTHENTICATOR,
        label: (
          <Row>
            <Col md={5}>
              <span className="m-l-5">{`${t('common.label.authenticator')}`}</span>
              <span className="m-l-5">{`(${t('common.label.recommended')})`}</span>
            </Col>
            <Col md={7} className="mr-top-4">
              <div className="row">
                <div className="col-lg-9">
                  <div className="btn-group m-dis-ib xs-full-width">
                    <button
                      className="btn btn-info m-r-10 xs-m-t-5 xs-m-l-r-0 xs-full-width"
                      type="button"
                      onClick={() => {
                        generateQR();
                        setView('SETUP_AUTHENTICATOR');
                      }}
                    >
                      {t('common.label.authenticator.configuration')}
                    </button>
                  </div>
                  <span className="m-dis-ib xs-m-t-5">
                    (
                    {authenticatorSecret
                      ? t('common.label.status.configured')
                      : t('common.label.status.not.configured')}
                    )
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        ),
        disabled: !originalUser.authenticatorSecret
      }
    ];
    if (mfaSMS) {
      optionMFAs.push({
        value: MFA_METHODS.SMS,
        label: (
          <Row>
            <Col md={5}>
              <span className="m-l-5">{t('common.label.sms')}</span>
              <span className="m-l-5">{`(${t('common.label.not.recommended')})`}</span>
              {sendSmsOtpCodeCountDown > 0 && (
                <span> ({t('label.count.seconds.left', { time: sendSmsOtpCodeCountDown })})</span>
              )}
              <div className="w-80 font-size-12 m-l-5">
                <span>{t('common.label.sms.warning')}</span>
              </div>
            </Col>
            <Col md={7} className="mr-top-4">
              <div className="row">
                <div className="col-xs-12 col-lg-9 m-dis-ib md-dis-ib">
                  <button
                    className="btn btn-info m-r-10 m-l-2 xs-full-width"
                    type="button"
                    onClick={() => {
                      setMobileStep(1);
                      setView('SETUP_MOBILE');
                    }}
                  >
                    {t('mfa.button.config.mobile')}
                  </button>
                  <span className="m-t-5 m-dis-ib">
                    ({mobilePhone ? t('common.label.status.configured') : t('common.label.status.not.configured')})
                  </span>
                  <div className="dis-flex m-t-10 m-l-2 mxs-dis-ib mxs-full-width">
                    {_countryCode && (
                      <div style={{ width: '150px' }} className=" m-m-l-0 xs-m-t-5 md-m-l-0">
                        <BaseInput disabled value={_countryCode} />
                      </div>
                    )}
                    {_mobilePhone && (
                      <div
                        style={{ width: '300px' }}
                        className={`${_countryCode &&
                          'm-l-5 '}xs-full-width xs-p-r-5 xs-m-t-5 xs-p-r-0 md-full-width m-p-l-0 mxs-m-l-0`}
                      >
                        <BaseInput disabled value={_mobilePhone} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        ),
        disabled: !mobilePhone || sendSmsOtpCodeCountDown > 0
      });
    }
    return (
      <div className="row justify-content-center">
        <div className="col-12 col-lg-9" style={{ display: 'inline-flex' }}>
          <span style={{ marginTop: '3px', marginRight: '10px' }}>{t('common.label.disable')}</span>
          <div>
            <BaseSwitch
              checked={mfaStatus}
              onChange={value => setMfaStatus(value)}
              disabled={mfaStatus && shouldDisabledMFA}
            />
          </div>
          <span style={{ marginTop: '3px', marginLeft: '10px' }}>{t('common.label.enable')}</span>
        </div>
        {!mfaStatus && (
          <div className="col-12 col-lg-9" style={{ marginTop: '20px', marginLeft: '30px' }}>
            <span>{t('mfa.msg.status.disable')}</span>
          </div>
        )}
        {mfaStatus && (
          <div className="col-12 col-lg-9" style={{ marginTop: '20px' }}>
            <span>{t('mfa.msg.status.enable')}</span>
            <Form
              className="col-12 m-t-20"
              action="#"
              autoComplete="off"
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <RadioList
                name="mfaMethod"
                className="m-t-15"
                options={optionMFAs}
                values={selectedMethod}
                onChange={value => onMethodChange(value)}
              />
            </Form>
          </div>
        )}
        <div className="col-12 col-lg-9 m-t-30 change-mfa-btns">
          <button className="btn btn-primary" onClick={handleSubmit} disabled={originalUser.mfa === mfaStatus}>
            {t('common.button.save')}
          </button>
          <button
            className="btn btn-default m-l-5"
            type="button"
            onClick={resetForm}
            disabled={originalUser.mfa === mfaStatus}
          >
            {t('common.button.reset')}
          </button>
        </div>
      </div>
    );
  };

  return 'SETTING' === view
    ? _renderSettingLayout()
    : 'SUCCESS' === view
    ? _renderSuccessLayout()
    : 'VERIFY_OTP' === view
    ? _renderVerifyOTPLayout()
    : 'SETUP_CONTACT_EMAIL' === view
    ? _renderContactEmailLayout()
    : 'SETUP_AUTHENTICATOR' === view
    ? _renderAuthenticatorLayout()
    : 'SETUP_MOBILE' === view
    ? _renderMobileLayout()
    : null;
}
export default TwoStepAuthentication;

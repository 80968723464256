/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { announcement as announcementService } from 'services';
import { addAnnouncement } from 'store/announcementSlice';
import { Button } from 'reactstrap';
import i18n from 'i18n';

const Announcement = props => {
  let sysLanguage = localStorage.getItem('sysLanguage');
  const dispatch = useDispatch();
  const announcements = useSelector(state => state.announcement.announcements);
  const { className } = props;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const fetchAnnouncements = await announcementService.getAnnouncements();
      addAnnouncement(fetchAnnouncements);
      dispatch(addAnnouncement(fetchAnnouncements));
    })();
  }, []);

  if (announcements && announcements.length) {
    require('./index.scss');
    return (
      <div className={className ? className : 'bg-announcement'}>
        {className && className.includes('small-screen-announcement') && isOpen && (
          <div className="btn_close_bar">
            <Button className="mr-2" close style={{ float: 'none' }} onClick={() => setIsOpen(!isOpen)}></Button>
          </div>
        )}
        <div className={`announcement-content${className && !isOpen ? ' close' : ''}`}>
          <h3>{i18n.t('Common.Menu.Announcements.SSO')}</h3>
          {announcements.map((announcement, index) => {
            const { contents } = announcement;
            let content = contents[sysLanguage] ? contents[sysLanguage].content : null;
            if (index < announcements.length - 1) {
              content += '<hr />';
            }
            return <div className={`announcement-${index}`} dangerouslySetInnerHTML={{ __html: content }} />;
          })}
        </div>
        {className && className.includes('small-screen-announcement') && !isOpen && (
          <div className={`btn_showhide_wrap${isOpen ? '' : ' closed'}`}>
            <button className="btn btn-info btn-cons m-t-12 btn-block btn_showhide" onClick={() => setIsOpen(!isOpen)}>
              <span className="btn_open" style={{ display: isOpen ? 'none' : 'inline' }}>
                <i>+</i>
                {i18n.t('Common.Button.Open')}
              </span>
            </button>
          </div>
        )}
      </div>
    );
  } else return null;
};

export default Announcement;

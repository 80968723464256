import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseInput, BaseLabelCounter, SearchBox } from 'components';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, FormFeedback, Input } from 'reactstrap';
import Table from 'components/BaseTable';
import MoreTextWithToolTip from 'components/MoreTextWithToolTip';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { logger } from 'core/logger';
import { errorUtils } from 'core/utils';
import { section as sectionService } from 'services';
import { SERVICE_PROVIDER } from 'core/constants';
import './index.scss';
import { map } from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import { convertTextUtils } from 'core/utils';
let scrollPositionHeight = 0;

function ResourceModal(props) {
  const { t } = useTranslation();
  const { resource, addSection, updateFullLoading, updateNotification, updateUser } = props;
  const [resourceLists, setResourceLists] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  const [sectionName, setSectionName] = useState('');
  const [errorSectionName, setErrorSectionName] = useState('');
  const [errorSelectedResources, setErrorSelectedResources] = useState('');
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const isEditForm = true;
  const mode = resource.sectionId !== 'add' ? 'edit' : 'add';
  const history = useHistory();
  const bodyRef = React.createRef(null);
  const threadRef = React.createRef(null);

  const serviceProviderTypeLabel = {
    [SERVICE_PROVIDER.AWS_MNG_CONSOLE]: t('common.label.awsMngConsole'),
    [SERVICE_PROVIDER.LYSITHEA_PC]: t('common.label.lysitheaPC'),
    [SERVICE_PROVIDER.LYSITHEA_SP]: t('common.label.lysitheaSP'),
    [SERVICE_PROVIDER.TIME_SHEET]: t('common.label.timeSheet'),
    [SERVICE_PROVIDER.WIKI]: t('common.label.samlWiki'),
    [SERVICE_PROVIDER.SAML_CUSTOM]: 'SAML Service Provider'
  };

  useEffect(() => {
    setErrorSelectedResources('');
    setErrorSectionName('');
    setResourceLists(resource.resources.data);
    setSelectedResources(resource.resourceIds);
    setSectionName(resource.sectionName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resource.resourceIds, resource.resources]);

  useEffect(() => {
    if (isEditForm) {
      const searchTextInstance = searchText
        ? searchText
            .toString()
            .slice(0)
            .trim()
        : '';
      const fullWidthSeachText = convertTextUtils.convertHalfwidthToFullwidth(searchTextInstance);
      const items = searchTextInstance.length
        ? resource.resources.data.filter(
            ({ name }) =>
              name.toLowerCase().indexOf(searchTextInstance.toLowerCase()) > -1 ||
              (fullWidthSeachText && name.toLowerCase().indexOf(fullWidthSeachText.toLowerCase()) > -1)
          )
        : resource.resources.data;
      setResourceLists(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const onSelectResources = id => {
    let list = selectedResources;
    if (list.includes(id)) {
      list = selectedResources.filter(_id => _id !== id);
    } else {
      list = [...list, id];
    }
    setSelectedResources(list);
    list.length
      ? setErrorSelectedResources('')
      : setErrorSelectedResources(t('Common.Validator.SelectAtLeastOne', { label: t('common.label.resource') }));
  };

  const checkboxOnClickHandler = (e, id) => {
    onSelectResources(id);
  };

  const getColumns = () => {
    return [
      {
        id: '_id',
        Header: '',
        accessor: 'id',
        Cell: row => {
          let rowid = row.original.id;
          const isChecked = selectedResources.filter(a => a === rowid).length ? true : false;
          return (
            <span className="m-auto ui-checkbox">
              <Input
                type="checkbox"
                name="resource_check"
                className="form-check-input"
                id={`resources_checkbox_${rowid}`}
                onChange={e => checkboxOnClickHandler(e, rowid)}
                checked={isChecked}
              />
              <label htmlFor={`resources_checkbox_${rowid}`}></label>
            </span>
          );
        },
        width: 50,
        sortable: false,
        filterable: false,
        className: 'text-center m-auto',
        style: { height: '40px' }
      },
      {
        Header: t('common.label.fieldName', { field: t('common.label.resource') }),
        accessor: 'name',
        minWidth: 150,
        Cell: row => <MoreTextWithToolTip>{row.value}</MoreTextWithToolTip>
      },
      {
        Header: t('common.label.applicationType'),
        accessor: 'serviceProviderType',
        minWidth: 150,
        Cell: row => (
          <MoreTextWithToolTip>
            {serviceProviderTypeLabel[row.value] ? serviceProviderTypeLabel[row.value] : ''}
          </MoreTextWithToolTip>
        )
      },
      {
        Header: t('common.label.description'),
        accessor: 'description',
        minWidth: 250,
        Cell: row => <MoreTextWithToolTip autohide={false}>{row.value}</MoreTextWithToolTip>
      }
    ];
  };

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop(scrollPositionHeight);
    }
  }, [bodyRef, currentPage]);

  const handlePageChange = data => {
    setCurrentPage(data.page);
    scrollPositionHeight = 0;
  };

  const handleStopScroll = () => {
    const { scrollTop } = bodyRef.current.getValues();
    scrollPositionHeight = scrollTop;
  };

  const handleScroll = ({ target: { scrollLeft: scrollWidth = 0 } }) => {
    threadRef.current.scrollLeft(scrollWidth);
  };

  const bodyComponent = tableState => {
    return (
      <Scrollbars ref={bodyRef} autoHeight onScrollStop={handleStopScroll} onScroll={handleScroll}>
        <div className="my-tbody-class">{tableState.children}</div>
      </Scrollbars>
    );
  };

  const theadComponent = tableState => {
    return (
      <div className="my-thead-class">
        <Scrollbars
          ref={threadRef}
          renderTrackVertical={props => <div {...props} className="track-vertical" style={{ display: 'none' }} />}
          renderThumbVertical={props => <div {...props} className="thumb-vertical" style={{ display: 'none' }} />}
          renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: 'none' }} />}
          renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />}
        >
          <div className="rt-thead -header">{tableState.children}</div>
        </Scrollbars>
      </div>
    );
  };

  const renderResourceList = () => {
    if (resourceLists.length) {
      return (
        <Table
          columns={getColumns()}
          initialized={true}
          data={resourceLists}
          searchFields={['name']}
          searchText={searchText}
          TbodyComponent={bodyComponent}
          TheadComponent={theadComponent}
          onChangePage={data => handlePageChange(data)}
          sortedRules={[
            {
              id: 'name',
              desc: false
            }
          ]}
        />
      );
    } else {
      return <div className="d-flex justify-content-center">{t('common.label.no_data_text')}</div>;
    }
  };

  const handleSave = async () => {
    try {
      let valid = true;
      if (!sectionName) {
        setErrorSectionName(
          t('common.validator.isRequired', {
            label: t('common.label.fieldName', { field: t('section.label.section') })
          })
        );
        valid = false;
      }
      const allResourceIds = resourceLists ? map(resourceLists, 'id') : [];
      const isSelectedResources = selectedResources.length
        ? selectedResources.some(id => allResourceIds.includes(id))
        : false;
      if (!isSelectedResources) {
        setErrorSelectedResources(t('Common.Validator.SelectAtLeastOne', { label: t('common.label.resource') }));
        valid = false;
      }

      if (!valid) {
        toast.warning(t('common.message.invalidInputs'));
        return;
      }
      let section = {
        sectionName,
        resourceIds: selectedResources
      };
      if (mode === 'edit') section.sectionId = resource.sectionId;
      updateFullLoading(true);
      if (mode === 'add') {
        await sectionService.addSection(section);
        toast.success(t('common.message.add.item.success'));
      } else {
        await sectionService.updateSection(section);
        toast.success(t('common.message.update.item.success'));
      }
      const sections = await sectionService.getSections();
      addSection(sections);
      handleClose();
      if (mode === 'add') {
        history.push('/');
      } else {
        history.push('/');
        history.push(`/section/${section.sectionId}`);
      }
    } catch (err) {
      logger.error('handleSave -> err', err);
      const { response } = err;
      const { data = {} } = response || {};
      const { code } = data;
      if (code === 'section-hit-limit') {
        toast.warning(t('section.message.hit.limit'));
        return;
      }
      if (code === 'unique_constraint') {
        toast.warning(t('common.message.unique.field', { field: t('section.label.section') }));
        return;
      }
      if (code === 'section-not-found') {
        toast.warning(t('common.message.item.not.found', { label: t('section.label.section') }));
        return;
      }
      errorUtils.handleError(err, toast, updateNotification, updateUser, history, t);
    } finally {
      updateFullLoading(false);
    }
  };

  const handleClose = () => {
    props.updateResourceModal({
      showModal: false,
      sectionId: 'add',
      resourceIds: [],
      sectionName: ''
    });
  };

  return (
    <Modal
      isOpen={resource.showModal}
      backdrop="static"
      keyboard={true}
      toggle={handleClose}
      size="lg"
      className={'resources-modal'}
    >
      <ModalHeader>
        {mode === 'edit'
          ? t(`common.header.edit`, { label: t('section.label.section') })
          : t('Common.Headers.New', { label: t('section.label.section') })}
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <BaseLabelCounter
            label={t('common.label.fieldName', { field: t('section.label.section') })}
            value={sectionName}
            length={20}
            required={true}
          />
          <BaseInput
            name="name"
            type="text"
            placeholder={t('placeholder.input.Enter', { field: t('section.label.section') })}
            maxLength={20}
            value={sectionName}
            onChange={value => {
              setSectionName(value);
              value
                ? setErrorSectionName('')
                : setErrorSectionName(
                    t('common.validator.isRequired', {
                      label: t('common.label.fieldName', { field: t('section.label.section') })
                    })
                  );
            }}
          />
          <FormFeedback className="d-block">{errorSectionName}</FormFeedback>
        </FormGroup>
        <FormGroup>
          <SearchBox
            placeholder={t('common.placeholder.searchBy', {
              field: t('common.label.fieldName', { field: t('common.label.resource') })
            })}
            value={searchText}
            handleSearch={setSearchText}
          />
        </FormGroup>
        <FormGroup className="m-b-0">
          <FormFeedback className="d-block">{errorSelectedResources}</FormFeedback>
          {renderResourceList()}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="default" variant="contained" onClick={handleClose}>
          {t('common.button.back')}
        </Button>
        <Button color="primary" variant="contained" onClick={handleSave}>
          {t('common.button.save')}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ResourceModal;

/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'announcement',
  initialState: {
    announcements: []
  },
  reducers: {
    addAnnouncement(state, { payload: announcements }) {
      state.announcements = announcements;
    }
  }
});

export default slice.reducer;

export const getAnnouncements = state => state.announcement.announcements;
export const { addAnnouncement } = slice.actions;

import React from 'react';
import { Announcement as AnnouncementComponent } from 'components';
import './index.scss';
import { useTranslation } from 'react-i18next';

function Announcement(props) {
  useTranslation();

  return (
    <div className="page-announcement">
      <AnnouncementComponent />
    </div>
  );
}

export default Announcement;

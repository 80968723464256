//import React, { Component } from 'react';
import 'assets/scss/theme/login.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import validator from 'core/validator/customValidator';
import { FormText, FormFeedback } from 'reactstrap';
import { toast } from 'react-toastify';
import BaseInput from 'components/BaseInput';
import { auth as authService } from 'services';
import { encodeBase64 } from 'core/helpers';

function Domain(props) {
  const { gotoToLogin, handleLoginError, appInfo = null, updateFullLoading } = props;
  const { t } = useTranslation();

  const [domainName, setDomainName] = useState('');
  const [errorDomainName, setErrorDomainName] = useState('');

  const handleChange = value => {
    setDomainName(value);
    validateDomain(value);
  };

  const validateDomain = domainName => {
    let err = '';
    if (!domainName) {
      err = 'common.validator.isRequired';
    }
    if (!validator.isDomainIP(domainName)) {
      err = 'domain.message.invalidFormat';
    }
    setErrorDomainName(err);
    if (err) {
      return false;
    }
    return true;
  };

  const handleSubmit = async e => {
    try {
      e.preventDefault();
      updateFullLoading(true);
      if (!validateDomain(domainName)) {
        toast.warning(t('common.message.invalidInputs'));
        return;
      }

      let state = encodeBase64('redirect=' + window.location.href);

      const domainLowerCase = domainName.toLowerCase();
      const lng = localStorage.getItem('sysLanguage') || 'en_US';
      let queryStr = `?domain=${domainLowerCase}&lng=${lng}&state=0000000000&redirect_uri=${encodeURIComponent(
        window.location.origin + '/auth/callback?state=' + state
      )}`;
      if (appInfo && appInfo.resourceId) {
        queryStr = `?domain=${domainLowerCase}&lng=${lng}&resource_id=${
          appInfo.resourceId
        }&state=0000000000&redirect_uri=${encodeURIComponent(
          window.location.origin + '/auth/callback?state=' + state
        )}`;
      }
      const { url } = await authService.redirectAuth(queryStr);
      return window.location.replace(url);
    } catch (err) {
      if (err.code === 'user-closed') {
        return;
      }

      if (err && err.source && ['react-devtools-bridge', 'react-devtools-inject-backend'].includes(err.source)) {
        return;
      }
      handleLoginError(err, appInfo);
    } finally {
      updateFullLoading(false);
    }
  };

  return (
    <>
      <form
        method="post"
        className="p-t-15 domain"
        name="domain-form"
        action="#"
        autoComplete="off"
        style={{ position: 'relative' }}
        onSubmit={handleSubmit}
      >
        <div className="form-group form-group-default">
          <label>{t('login.domainName.label')}</label>
          <BaseInput
            type="input"
            className="form-control"
            name="domainName"
            value={domainName}
            onChange={handleChange}
            placeholder={t('placeholder.input.Enter', { field: t('login.domainName.label') })}
          />
        </div>
        <button className={`btn btn-primary btn-cons m-t-10 btn-block btn-auth`} type="submit" onClick={handleSubmit}>
          <i className="material-icons">arrow_right_alt</i>
        </button>
        <FormText>{t('domain.hint.text')}</FormText>
        <FormFeedback className="d-block">
          {errorDomainName ? t(errorDomainName, { label: t('login.domainName.label') }) : ''}
        </FormFeedback>
      </form>
      <div className="d-flex align-items-center">
        <div className="m-r-5" style={{ fontSize: '0.8rem' }}>
          {t('common.label.already.login')}
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a style={{ marginLeft: '4px', color: '#007bff' }} href="#" onClick={() => gotoToLogin()}>
            {t('login.signin.button')}
          </a>
        </div>
      </div>
    </>
  );
}

export default Domain;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BaseInput from 'components/BaseInput';
import './index.scss';

export default class SearchBox extends PureComponent {
  static propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string,
    handleSearch: PropTypes.func
  };

  static defaultProps = {
    value: '',
    handleSearch: () => {}
  };

  constructor(props) {
    super(props);
    this.state = { searchText: '' };
  }

  componentWillReceiveProps(nextProps) {
    const { value } = nextProps;
    if (value !== this.props.value)
      this.setState({
        searchText: nextProps.value || ''
      });
  }

  onChange(value) {
    this.setState({ searchText: value });
    this.props.handleSearch(value);
  }

  render() {
    const { placeholder } = this.props;
    const { searchText } = this.state;
    return (
      <div className="d-flex search-box">
        <label>
          <span className="m-t-4 material-icons md-18">search</span>
        </label>
        <div className="w-100">
          <BaseInput
            name="search"
            placeholder={placeholder}
            onChange={value => this.onChange(value)}
            value={searchText}
          />
        </div>
      </div>
    );
  }
}

import React from 'react';
import { Alert, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const AlertSetUpMFA = props => {
  const { currentUser, history } = props;
  const { t } = useTranslation();

  const onSetUp2FA = () => {
    localStorage.setItem('PROFILE_SETTING_TAB', 'SETTING_MFA');
    localStorage.setItem('action', 'SETTING_MFA');
    history.push('/profile-settings');
  };

  if (!currentUser.mfa) {
    require('./index.scss');
    return (
      <Alert className="border border-danger" color="danger">
        <p dangerouslySetInnerHTML={{ __html: t('AlertSetUpMFA.Description') }} />
        <Button onClick={onSetUp2FA}>{t('AlertSetUpMFA.Button.Setup')}</Button>
      </Alert>
    );
  } else return null;
};

export default AlertSetUpMFA;

export { default as errorUtils } from './errorUtils';
export { default as makeid } from './makeid';
export { default as stringUtils } from './stringUtils';
export { default as cookies } from './cookies';
export { default as formatDateTime } from './formatDateTime';
export { default as objUtils } from './objectUtils';
export { default as convertTextUtils } from './convertText';

export const resetLocalStorage = () => {
  const i18nextLng = localStorage.getItem('i18nextLng');
  const pinnedMenu = localStorage.getItem('pinnedMenu');
  const modeLogin = localStorage.getItem('modeLogin');
  localStorage.clear();
  if (pinnedMenu) localStorage.setItem('pinnedMenu', pinnedMenu);
  localStorage.setItem('i18nextLng', i18nextLng);
  localStorage.setItem('sysLanguage', i18nextLng);
  localStorage.setItem('modeLogin', modeLogin);
};
